import {ActionIcon, Box, Flex, Input, NumberInput, Popover, Radio, Select, Space, TextInput} from "@mantine/core";
import { IconCode, IconFileUpload } from "@tabler/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const SendTextToSelector = (props) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        elementSelect: "",
        fixedElement: 1,
        content:"",
        contentType: "sequence",
        intervalTime: 100,
        timeout: 30000,
    });
    const data = useContext(DataFlowContext)

    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({ ...dataState, [type]: value });
        } else {
            setDataState({ ...dataState, [type]: value.target.value });
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const rightSection = (<ActionIcon radius="md" onClick={handleDropdown} variant="filled" color="yellow"><IconCode size={16} /></ActionIcon>)

    return (
        <>
            <Box component="form" mx="auto">
                <VariableSelectWrite
                    style={{marginBottom:"10px"}}
                    dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                    setDataState={handleSelector}
                    handleData={(e) => {
                        handleSelector('elementSelect', e)
                    }}
                    handleSelect={(e) => {
                        const value = e.target.getAttribute('value')
                        handleSelector('elementSelect', dataState.elementSelect + "${" + value + "}")
                    }}
                />
                <Flex
                    mih={50}
                    gap="md"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    <VariableNumberInput
                        label="Element order"
                        style={{width:"200px"}}
                        dataState={dataState.fixedElement}
                        handleData={(e) => {
                            handleSelector('fixedElement', e)
                        }}
                    />
                </Flex>
                <Flex
                    style={{marginTop:"10px"}}
                    mih={50}
                    justify="flex-start"
                    align="flex-start"
                    direction="column"
                    wrap="wrap"
                >
                    <VariableTextarea
                        label="Content"
                        style={{width:"100%"}}
                        dataState={dataState.content}
                        handleData={(e) => {
                            handleSelector("content", e)
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('content', dataState.content + "${" + value + "}")
                        }}
                        placeholder="Please enter a single content on one line;
                        Please enter multiple content in a new line, and one of the content will be random selected. Example:
                        Content one
                        Content two"
                    />
                </Flex>

                <VariableNumberInput
                    label="Enter interval time  (milliseconds)"
                    dataState={dataState.intervalTime}
                    handleData={(e) => {
                        handleSelector('intervalTime',e)
                    }}
                    isMillisecond={true}
                />
            </Box>
        </>
    )
}
export default SendTextToSelector;