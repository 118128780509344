import { Text } from '@mantine/core';
import React from 'react';
import { useStyles } from './style';

export default function ContextMenu({ id, top, left, right, bottom, deleteNode, copyNode, duplicateNode, pasteNode, onPane = false, ...props }) {
  const { classes } = useStyles();
  
  const MENU = [
    {
      action: (event) => pasteNode(onPane, event),
      type: 'Paste',
      shortcut: 'Ctrl + V',
    },
    {
      action: () => copyNode(),
      type: 'Copy',
      shortcut: 'Ctrl + C',
      onPane: onPane,
    },
    {
      action: () => duplicateNode(id),
      type: 'Duplicate',
      shortcut: 'Ctrl + D',
      onPane: onPane,
    },
    {
      action: () => deleteNode(id),
      type: 'Delete',
      shortcut: 'Del',
      onPane: onPane,
    },
  ]

  return (
    <div
      style={{ top, left, right, bottom }}
      className={classes.contextMenu}
      {...props}
    >
      <div className={classes.spinContainer}>
        {
          MENU.map((item) => (
            <li key={item.type} className="list-item" onClick={item.action} style={(onPane && item.type !== 'Paste') ? { display: 'none' } : {}}>
              <span style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
                <Text style={{ marginRight: '20px' }}>{item.type}</Text>
                <Text className={classes.textShortcut}>{item.shortcut}</Text>
              </span>
            </li>
          ))
        }
      </div>
    </div>
  );
}
