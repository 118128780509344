import React, {useEffect, useState} from "react";
import {Menu, ActionIcon, Grid, Select, TextInput} from "@mantine/core";
import {IconCode} from "@tabler/icons";
import VariableMenu from "../../../GlobalComponent/VariableMenu";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const IfCode = (props) => {
    const [opened, setOpened] = useState(false);
    const [dataState, setDataState] = useState( props?.nodeData?.data?.options??{
        leftVariable: "",
        operator: "",
        rightVariable: "",
    })
    
    const handleChange = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""});
        }
        else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const iconSection = (
        <ActionIcon radius="md"><IconCode size={16} /></ActionIcon>
    )

    return (
        <>
            <VariableSelect
                label="Variable"
                placeholder="Select left operand"
                value={dataState.leftVariable}
                handleChange={(e) => {
                    handleChange('leftVariable', e)
                }}
            />
            <Select
                style={{marginTop: "10px"}}
                label="Operator"
                placeholder="Select operator"
                value={dataState.operator}
                onChange={(e) => {
                    handleChange('operator', e)
                }}
                data={[
                    { value: '<', label: '<' },
                    { value: '>', label: '>' },
                    { value: '=', label: '=' },
                    { value: '!=', label: '!=' },
                    { value: '<=', label: '<=' },
                    { value: '>=', label: '>=' },
                    { value: 'startsWith', label: 'Starts with' },
                    { value: 'endsWith', label: 'Ends with' },
                    { value: 'contains', label: 'Contains' },
                ]}
            />
            {(dataState.operator !== 'exist' && dataState.operator !== 'notExist') &&
                <VariableSelectWrite
                    style={{marginTop: "10px"}}
                    label="Variable or value"
                    placeholder="Select right operand"
                    dataState={dataState.rightVariable}
                    setDataState={handleChange}
                    handleData={(e) => {
                        handleChange('rightVariable', e)
                    }}
                    handleSelect={(e) => {
                        const value = e.target.getAttribute('value')
                        handleChange('rightVariable', dataState.rightVariable + "${" + value + "}")
                    }}
                />
            }
        </>
    );
}

export default IfCode;