import { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

function Disconnection(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
		reconnectTime: '2000',
    })
	const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <VariableSelectWrite
                label="Reconnect time (miliseconds)"
                dataState={dataState.reconnectTime || ""}
                handleData={onChangeValue('reconnectTime')}
                handleSelect={onChangeValue('reconnectTime')}
            />
        </div>
    );
}

export default Disconnection;