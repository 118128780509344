import { NumberInput  } from "@mantine/core";
import React, {useState } from "react";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";


const GoBack = (props) => {
    const [timeout, setTimeOut] = useState(props?.nodeData?.data?.options?.timeout == 0 ? 10000 : props?.nodeData?.data?.options?.timeout);
    
    const handleChangeTimeOut = (e) => {
        setTimeOut(e)
        props.handleSetDataBaseModal({ timeout: e })
    }

    return (
        <>
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={timeout}
                handleData={(e) => {
                    handleChangeTimeOut(e)
                }}
                isMillisecond={true}
            />
        </>
    )
}
export default GoBack;