import { Button } from "@mantine/core"
import { IconFileCode, IconFileDownload, IconLayersSubtract, IconPlayerPlay, IconTrash } from "@tabler/icons"
import React, { useContext, useEffect, useRef, useState } from "react"
import ModalStatus from "./modalStatus"
import { ScriptContext } from "../context/ScriptContext"
import { systemNotifications } from "../../components/Notifications/Notifications"
import { useSearchParams } from "react-router-dom"
import { ListProfileProvider } from "../context/ListProfilesContext"
import ComponentConfirm from "../../components/GlobalComponent/ComponentConfirm"

const modalDataStatus = {
    modalStart: {
        type: 'Start',
        title: "Modal Start",
        label: 'Run on uuid',
        placeholder: 'Choose a profile'
    },
    modalSave: {
        type: 'Save',
        title: "Modal Save",
        label: 'Name script',
        placeholder: 'Enter name script'
    },
    modalSaveAs: {
        type: 'SaveAs',
        title: "Modal Save As",
        label: 'Name script',
        placeholder: 'Enter name script'
    }
}

const nodeInstance = ["nodeStart", "nodeVariables"]

const ActionStatus = ({ props, nodes }) => {
    // const [listProfile, setListProfile] = useState([]);
    const [modalName, setModalName] = useState('')
    const [showModal, setShowModal] = useState(false);
    const scriptContext = useContext(ScriptContext);
    const [uuidProfile, setUuidProfile] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const askBeforeDelete = useRef(null)

    const [searchParams] = useSearchParams();
    const isClone = searchParams.get('is_clone_script') === "true"

    const handleOpenModal = (item) => {
        setShowModal(true)
        setModalName(item)
    }

    const handleSaveAs = (values) => {
        props.handleSaveAutomationFlow(values.name, true);
        scriptContext.setScriptName(values.name);
        setShowModal(false);
    }

    const handleError = (errors) => {
        if (errors.name) {
            systemNotifications({
                type: 'error',
                color: 'red',
                message: errors.name
            });
        }
        if (errors.profile) {
            systemNotifications({
                type: 'error',
                color: 'red',
                message: errors.profile
            });
        }
    };

    const handleSubmit = (values) => {
        let dataType = {
            type: 'error'
        }
        if (values.name.trim() && isClone) {
            dataType = props.handleSaveAutomationFlow(values.name, true);
        } else if (values.name.trim() && !isClone) {
            dataType = props.handleSaveAutomationFlow(values.name, false);
        } else {
            systemNotifications({
                type: 'error',
                color: 'red',
                message: 'The name field is required.'
            });
        }
        if (dataType?.type !== 'error') {
            setShowModal(false);
        }
        scriptContext.setScriptName(values.name);
    };

    useEffect(() => {
        const confirmListener = (e) => {
            if (e.data?.type === 'action' && e.data?.content === 'save_script') {
                handleOpenModal(modalDataStatus.modalSave)
            }
        }
        window.addEventListener("message", confirmListener)
        return () => window.removeEventListener('message', confirmListener)
    }, [])

    const handleStart = () => {
        //98606546-0f48-4cbb-8d94-68aa86930473
        if (!uuidProfile.uuid) {
            handleError({ profile: "Choose a profile to run on" })
            return
        } else {
            setShowModal(false);
        }
        props.handleRunAutomationFlow(uuidProfile.uuid, uuidProfile.browser_name);
        props.handleShowLogAutomationFlow()
    }

    const handleShowLog = () => {
        props.handleShowLogAutomationFlow()
    }

    const handleDeleteNodes = async () => {
        setOpenModal(true)
        const isConfirmed = await confirm()

        if (isConfirmed) {
            props.handleRemoveNodes()
        }
    }

    const onConfirm = () => {
        setOpenModal(false)
        askBeforeDelete.current(true)
    }

    const onCancel = () => {
        setOpenModal(false)
        askBeforeDelete.current(false)
    }

    const confirm = () => {
        return new Promise((resolve, reject) => {
            askBeforeDelete.current = resolve
        })
    }

    return (
        <>
            <ListProfileProvider>
                <Button leftIcon={<IconPlayerPlay size={16} />} onClick={() => handleOpenModal(modalDataStatus.modalStart)} variant="outline" color="teal">Start</Button>
                <Button leftIcon={<IconFileDownload size={16} />} onClick={() => handleOpenModal(modalDataStatus.modalSave)} variant="outline">Save</Button>
                {scriptContext.scriptId && !isClone &&
                    <Button leftIcon={<IconLayersSubtract size={16} />} onClick={() => handleOpenModal(modalDataStatus.modalSaveAs)} variant="outline">Save as</Button>
                }
                {
                    <Button leftIcon={<IconFileCode size={16} />} onClick={handleShowLog} variant="outline">Work log</Button>
                }
                <Button 
                    disabled={nodes.filter(node => node.selected && !nodeInstance.includes(node.type)).length === 0 ? true : false}
                    leftIcon={<IconTrash size={16} />} 
                    onClick={(e) => handleDeleteNodes()}
                    variant="outline" 
                    color="red"
                >
                    Delete
                </Button>
                {showModal &&
                    <ModalStatus
                        show={showModal}
                        setShowModal={setShowModal}
                        handleSaveAs={handleSaveAs}
                        handleError={handleError}
                        handleSubmit={handleSubmit}
                        modalName={modalName}
                        setUuidProfile={setUuidProfile}
                        handleStart={handleStart}
                    />
                }
                {openModal && 
                    <ComponentConfirm
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                    />
                }
            </ListProfileProvider>
        </>
    )
}

export default ActionStatus