import { ActionIcon, Button, Checkbox, Input, Radio, Text, Tooltip } from '@mantine/core';
import { useForm } from "@mantine/form";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from "uuid";

import { IconFileUpload, IconInfoCircle, IconPlus, IconTrash } from '@tabler/icons';
import { replaceVariablesWithValue } from '../../../../constants/replaceVariablesWithValue.const';
import { DataFlowContext } from '../../../../core/context/DataFlowContext';
import VariableSelectWrite from '../../../GlobalComponent/VariableSelectWrite';
import VariableSelectCreate from '../../../GlobalComponent/VariableSelectCreate';
import { AppSettingsContext } from '../../../../core/context/AppSettingsContext';
import { SHEET_NAME } from './mockData';
import { versions } from '../../../../core/Navbar/automationVersion';

const DESCRIBE_KEY = {
    vi: `
    Khi chọn checkbox ( firsr row as key) này thì script sẽ tự hiểu dòng đầu tiên của range bạn chọn là title của các cột dữ liệu, các trường column bên dưới cần ghi là title của cột. 
    Khi không chọn checkbox ( firsr row as key) này thì script sẽ không lấy dòng đầu tiên của range bạn chọn là title của các cột dữ liệu nữa và các trường column bên dưới cần ghi là A B C D,.. 
    `,
    en: `
    When you select this checkbox (first row as key), the script will automatically understand that the first line of the range you select is the title of the data columns. The column fields below need to be written as column titles. 
    When this checkbox (first row as key) is not selected, the script will no longer take the first line of the range you select as the title of the data columns and the column fields below need to be recorded as A B C D, etc.
    `
}
const DESCRIBE_STOP = {
    vi: `
    - Trong trường hợp muốn random các dòng dữ liệu vào từng profile không theo thứ tự thì bỏ checkbox này và dùng node random đằng trước VD : random 1-10 => range = A\${output_variable_random}:B10 - Nếu bỏ checkbox và không dùng "Random" thì tất cả profile đều dùng chung dòng đầu tiên trong range bạn chọn
    `,
    en: `
    If you want to randomize the data lines in each profile, you can uncheck the "Read row with the stop condition" checkbox and use the "Random" node instead. For instance, you can use "Random 1-10" to randomize the lines from A\${output_variable_random}:B10. If you don't use "Random" and remove the checkbox, all profiles will use the same first line in the range you choose. 
    `
}

const defaultState = {
    type: 'localFile',
    sheetId: '',
    filePath: '',
    range: '',
    sheetName: '',
    firstRowAsKey: false,
    stopRow: true,
    columnToCompare: '',
    valueToCompare: '',
    columns: [{ name: "", variableToSave: "", id: uuid() }],
    rowData: '',
    totalRaw: '',
    fileJsonPath: '',
}

function Spreadsheet(props) {
    const { settings } = useContext(AppSettingsContext)
    const defaultOptions = props?.nodeData?.data?.options ? {
        ...props?.nodeData?.data?.options,
        type: settings.autoVersion >= versions[2].autoVersion ? props.nodeData.data.options.type : 'localFile' 
    } : defaultState
    const fileRef = useRef(null)
    const fileJsonRef = useRef(null)
    const [dataState, setDataState] = useState(defaultOptions)
    const [loading, setLoading] = useState(false)
    const [preview, setPreview] = useState(null)
    const variablesContext = useContext(DataFlowContext)
    const form = useForm({
        initialValues: {
            columns: props?.nodeData?.data?.options?.columns ?? defaultState.columns,
        },
      });
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    const onChangeFormValue = (key, index) => (e) => {
        const formKey = 'columns'
        const newRowValue = cloneDeep(form.values[formKey][index]);
        if (e?.type === "change") {
          newRowValue[key] = e.target.value;
        } else if (e?.type === "click") {
          const value = e.target.getAttribute("value");
          const newValue = newRowValue[key] + "${" + value + "}";
          newRowValue[key] = newValue;
        } else {
          newRowValue[key] = e;
        }
        if (key === "type") {
          newRowValue.value = "";
        }
        const newBody = cloneDeep(form.values[formKey]);
        newBody[index] = newRowValue;
        form.setFieldValue(formKey, newBody);
        setDataState((state) => ({...state, [formKey]: newBody}))
      };

    useEffect(() => {
        props.handleSetDataBaseModal({...dataState, storeVariable: form.values.storeVariable })
    }, [props, dataState])

    const onPreview = async () => {
        const { nodeValue } = variablesContext;
        const dataPreview = { ...dataState }

        const newDataState = replaceVariablesWithValue(dataPreview, nodeValue?.variables)
        setPreview(null)
        setLoading(true)
        const result = await window.api.ipcRenderer.invoke("spreadsheet", newDataState)
        setPreview(result)
        setLoading(false)
    }
    const addNewRow = () => {
        const storeVariable = [...form.values.columns];
        const newColumn =  [
            ...storeVariable,
            { name: "", value: "", type: "text", id: uuid() },
        ]
        form.setFieldValue('columns', newColumn);
        setDataState((state) => ({...state, columns: newColumn}))
    };
    const removeRow = (id) => {
        const newRows = form.values.columns.filter(({ id: itemId }) => itemId !== id);
        form.setFieldValue('columns', newRows);
        setDataState((state) => ({...state, columns: newRows}))
    };
    const handleUpload = (type) => {
        if (type === 'fileJson') {
            setDataState({...dataState, fileJsonPath: fileJsonRef.current.files[0].path});
        } else if (type === 'file'){
            setDataState({...dataState, filePath: fileRef.current.files[0].path});
        }
    }

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    return (
        <>
            <div>
                <Radio.Group
                    value={dataState.type}
                    label="Choose file type"
                    onChange={onChangeValue("type")}
                    withAsterisk
                >
                    <Radio value="localFile" label="Local file" />
                    {
                        settings.autoVersion >= versions[2].autoVersion && (
                            <Radio value="googleSheet" label="Google Sheet" />
                        )
                    }
                </Radio.Group>
                {
                    dataState.type === "googleSheet" ? (
                        <>
                            <VariableSelectWrite
                                label="Google Spreadsheet ID"
                                placeholder="Enter text or choose variable"
                                dataState={dataState.sheetId}
                                handleData={onChangeValue('sheetId')}
                                handleSelect={onChangeValue('sheetId')}
                            />
                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                <VariableSelectWrite
                                    style={{ flex: 1 }}
                                    placeholder="JSON file"
                                    label="Credential file"
                                    dataState={dataState.fileJsonPath}
                                    // setDataState={onChangeValue('fileJsonPath')}
                                    handleData={onChangeValue('fileJsonPath')}
                                    handleSelect={onChangeValue('fileJsonPath')}
                                />
                                <Input
                                    className="custom-file-input"
                                    type="file"
                                    ref={fileJsonRef}
                                    onChange={() => handleUpload('fileJson')}
                                    icon={<IconFileUpload size={16} />}
                                    accept=".json"
                                    mt="0px"
                                    styles={(theme) => ({
                                        input: {
                                            paddingRight: "0px",
                                            '&::-webkit-file-upload-button': {
                                                visibility: 'hidden',
                                            },
                                            cursor: 'pointer',
                                        },
                                    })}
                                >
                                </Input>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                placeholder="Enter full path or upload file"
                                label="Path to the file"
                                dataState={dataState.filePath}
                                // setDataState={onChangeValue('filePath')}
                                handleData={onChangeValue('filePath')}
                                handleSelect={onChangeValue('filePath')}
                            />
                            <Input
                                className="custom-file-input"
                                type="file"
                                ref={fileRef}
                                onChange={() => handleUpload('file')}
                                icon={<IconFileUpload size={16} />}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                mt="0px"
                                styles={(theme) => ({
                                    input: {
                                        paddingRight: "0px",
                                        '&::-webkit-file-upload-button': {
                                            visibility: 'hidden',
                                        },
                                        cursor: 'pointer',
                                    },
                                })}
                            >
                            </Input>
                        </div>
                    )
                }
                <div style={{ display: "flex", alignItems: 'flex-end', marginTop: 10 }}>
                    <div style={{ flex: 1 }}>
                        <label style={{fontWeight: 500, fontSize: 14}}>Range</label>
                        <VariableSelectWrite
                            style={{marginTop: 3}}
                            placeholder="EX: A1:C5"
                            dataState={dataState.range}
                            handleData={onChangeValue('range')}
                            handleSelect={onChangeValue('range')}
                        />
                    </div>
                    <div style={{ flex: 1, marginLeft: 10 }}>
                        <div style={{ display: "flex" , alignItems: 'center'}}>
                            <label style={{fontWeight: 500, fontSize: 14}}>Sheet name (Optional)</label>
                            <Tooltip label={SHEET_NAME[settings.language] ?? SHEET_NAME.en} withArrow>
                                <ActionIcon>
                                    <IconInfoCircle/>
                                </ActionIcon> 
                            </Tooltip>
                        </div>
                        <VariableSelectWrite
                            style={{marginTop: 0}}
                            placeholder="Enter text or choose variable"
                            dataState={dataState.sheetName}
                            handleData={onChangeValue('sheetName')}
                            handleSelect={onChangeValue('sheetName')}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <Checkbox
                        style={{ marginTop: 12 }}
                        checked={dataState.firstRowAsKey}
                        label="First row as title (Column name)"
                        onChange={onChangeValue('firstRowAsKey', 'checked')}
                    />
                    <Tooltip label={DESCRIBE_KEY[settings.language] ?? DESCRIBE_KEY.en} multiline w={400} withArrow>
                        <ActionIcon>
                            <IconInfoCircle/>
                        </ActionIcon> 
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <Checkbox
                        style={{ marginTop: 12 }}
                        checked={dataState.stopRow}
                        label="Read row with stop condition"
                        onChange={onChangeValue('stopRow', 'checked')}
                    />
                    <Tooltip label={DESCRIBE_STOP[settings.language] ?? DESCRIBE_STOP.en} multiline w={400} withArrow>
                        <ActionIcon>
                            <IconInfoCircle/>
                        </ActionIcon> 
                    </Tooltip>
                </div>
                {
                    dataState.stopRow && (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <VariableSelectWrite
                                    style={{ flex: 1 }}
                                    label="Column to compare"
                                    placeholder="A"
                                    dataState={dataState.columnToCompare}
                                    handleData={onChangeValue('columnToCompare')}
                                    handleSelect={onChangeValue('columnToCompare')}
                                />
                                <span style={{ marginBottom: 4, fontSize: 20, marginLeft: 12, marginRight: 12 }}>=</span>
                                <VariableSelectWrite
                                    style={{ flex: 1 }}
                                    label="Value to compare"
                                    dataState={dataState.valueToCompare}
                                    handleData={onChangeValue('valueToCompare')}
                                    handleSelect={onChangeValue('valueToCompare')}
                                />
                            </div>
                        </div>
                    )
                }
                <label 
                    style={{ marginTop: 12 }} 
                    className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2"
                >Variable output</label>
                <div style={{ display: 'flex', marginTop: 8 }}>
                    <VariableSelectCreate
                       style={{ flex: 1 }}
                       label="Row match conditions"
                       placeholder="Variable"
                       value={dataState.rowData}
                        handleChange={(e) => {
                            handleSelector('rowData', e)
                        }}
                    />
                    <VariableSelectCreate
                        style={{ flex: 1, marginLeft: 32 }}
                        label="Last row of data"
                        placeholder="Variable"
                        value={dataState.totalRaw}
                        handleChange={(e) => {
                            handleSelector('totalRaw', e)
                        }}
                    />
                </div>
                <label
                    style={{ marginTop: 12 }}
                    className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2"
                >Storage</label>
                {
                    form.values.columns.map(({ name, variableToSave, id }, index) => (
                        <div key={id} style={{ display: "flex", alignItems: "end" }}>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                label="Column name"
                                dataState={name}
                                handleData={onChangeFormValue('name', index)}
                                handleSelect={onChangeFormValue('name', index)}
                            />
                            <VariableSelectCreate
                                style={{ flex: 1, marginLeft: 12 }}
                                label="Save to"
                                value={variableToSave}
                                handleChange={onChangeFormValue('variableToSave', index)}
                            />
                            <Button
                                style={{ width: "85px", marginLeft: "12px" }}
                                color="red"
                                onClick={() => removeRow(id)}
                            >
                                <IconTrash size={20} />
                            </Button>
                        </div>
                    ))
                }
                <div style={{ textAlign: "end" }}>
                    <Button
                        rightIcon={<IconPlus size={16} />}
                        mt="sm"
                        onClick={addNewRow}
                    >
                    Add
                    </Button>
                </div>
            </div>
            <div style={{ marginTop: 12 }}>
                <Button loading={loading} onClick={onPreview} variant="filled" size='xs'>Preview</Button>
                {
                    preview && (
                        <div style={{ marginTop: 12 }}>
                            {
                                preview?.status ? (
                                    <pre style={{ backgroundColor: "#000", color: "#fff", padding: "12px", minWidth: "100%", width: "max-content", borderRadius: 12 }}>
                                        {JSON.stringify(preview.data, null , 4)}
                                    </pre>
                                ) : (
                                    <p style={{ backgroundColor: "#000", color: "red", padding: "12px", width: "max-content", minWidth: "100%", borderRadius: 12 }}>
                                        { preview.message }
                                    </p>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default Spreadsheet;
