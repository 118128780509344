import React, { useEffect, useState } from 'react';
import { TextInput, ActionIcon } from '@mantine/core';
import { IconCode } from '@tabler/icons';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const NewTab = (props) => {
    const [newTab, setNewTab] = useState(props?.nodeData?.data?.options?.newTab ?? "");

    const handleChangeNewTab = (e) => {
        if (typeof e === 'string') {
            setNewTab(e)
            props.handleSetDataBaseModal({ newTab: e })
        } else {
            setNewTab(e.target.value)
            props.handleSetDataBaseModal({ newTab: e.target.value })
        }
    }

    const rightSection = (<ActionIcon radius="md"><IconCode size={16} /></ActionIcon>)

    useEffect(() => {
        props.handleSetDataBaseModal({ newTab })
    }, [props, newTab])

    return (
        <>
            <VariableSelectWrite
                placeholder="Enter URL"
                label="URL"
                dataState={newTab}
                handleData={(e) => {
                    handleChangeNewTab(e)
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleChangeNewTab(newTab + "${" + value + "}")
                }}
            />
        </>
    );
}

export default NewTab;
