import React, { useState, useEffect, useContext } from 'react';
import { Box, Radio } from '@mantine/core';
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import { AppSettingsContext } from '../../../../core/context/AppSettingsContext';
import { versions } from '../../../../core/Navbar/automationVersion';

const CloseTab = (props) => {
    const { settings } = useContext(AppSettingsContext)
    const [radioTab, setRadioTab] = useState(props?.nodeData?.data?.options?.closeTabType ?? 'current');
    const [tabNumber, setTabNumber] = useState(props?.nodeData?.data?.options?.tabNumber ?? 1);

    const handleChangeTabNumber = (number) => {
        if(!!number && Number(number.trim()) === 0 ){
            setTabNumber(1);
        }
        else{
            setTabNumber(number);
        }
    }

    const handleSetDataBaseModal = props.handleSetDataBaseModal;
    useEffect(() => {
        const options = {
            closeTabType: radioTab,
            tabNumber: tabNumber
        }

        handleSetDataBaseModal(options)

    }, [radioTab, tabNumber, handleSetDataBaseModal]);

    return (
        <>
            <Box component="form" mx="auto">
                <Radio.Group
                    mt="md"
                    value={radioTab}
                    name="close-tab"
                    label="Choose one"
                    spacing="sm"
                    withAsterisk
                    onChange={setRadioTab}
                >
                    <Radio value="current" label="Current" />
                    <Radio value="custom" label="Select tab" />
                    {
                        settings.autoVersion >= versions[1].autoVersion && <Radio value="otherAll" label="Other all tab" />
                    }
                </Radio.Group>
                {
                    radioTab === 'custom' &&
                    <>
                        <VariableNumberInput
                            label="Tab number"
                            dataState={tabNumber}
                            handleData={(e) => {
                                handleChangeTabNumber(e)
                            }}
                        />
                    </>
                }
            </Box>
        </>
    );
}

export default CloseTab;
