import { useEffect, useState } from "react";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";

function AddLog(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
		content: '',
    })
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <VariableTextarea
            dataState={dataState.content}
            handleData={onChangeValue('content')}
            handleSelect={onChangeValue('content')}
        />
    );
}

export default AddLog;
