import { Text } from "@mantine/core";
import { IconVariable } from "@tabler/icons";
import React from "react";
import './Variables.css'

const Variables = ({ data }) => {
    return (
        <>
            <div className="node-variable">
                <IconVariable size={16} />
                <Text size="0.8rem">{data.label}</Text>
            </div>
        </>
    )
}

export default Variables