import {Flex, NumberInput, Radio, Select, Space, TextInput} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import { DataFlowContext } from "../../../../core/context/DataFlowContext";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const GetValue = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        attributeName: "",
        outputVariable: "",
        elementSelect: '',
        elementOrder: 'fixed',
        fixedElement: 1,
        randomElementFrom: 1,
        randomElementTo: 50,
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                label="Attribute name"
                placeholder="Enter number or insert variable"
                dataState={dataState.attributeName}
                setDataState={handleSelector}
                handleData={(e) => {
                    handleSelector('attributeName', e)
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleSelector('attributeName', dataState.attributeName + "${" + value + "}")
                }}
            />
            <VariableSelectWrite
                style={{marginBottom:"10px"}}
                dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                setDataState={handleSelector}
                handleData={(e) => {
                    handleSelector('elementSelect', e)
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleSelector('elementSelect', dataState.elementSelect + "${" + value + "}")
                }}
            />
            <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="flex-start"
                direction="row"
                wrap="wrap"
            >
                <Select
                    value={dataState.elementOrder}
                    label="Element order"
                    placeholder="Pick one"
                    style={{width:"150px"}}
                    data={[
                        { value: 'fixed', label: 'Fixed Value' },
                        { value: 'random', label: 'Random Value' },
                    ]}
                    onChange={(e) => {
                        handleSelector('elementOrder', e)
                    }}
                />
                {dataState.elementOrder === "fixed" &&
                    <VariableNumberInput
                    style={{width:"200px"}}
                    label=" "
                    dataState={dataState.fixedElement}
                    handleData={(e) => {
                        handleSelector('fixedElement', e)
                    }}
                />
                }
                {dataState.elementOrder === "random" &&
                    <>
                        <NumberInput
                            style={{width:"150px"}}
                            value={dataState.randomElementFrom}
                            label="from"
                            min={1}
                            onChange={(e) => {
                                handleSelector('randomElementFrom', e)
                            }}
                        />
                        <NumberInput
                            style={{width:"150px"}}
                            value={dataState.randomElementTo}
                            label="to"
                            min={1}
                            onChange={(e) => {
                                handleSelector('randomElementTo', e)
                            }}
                        />
                    </>
                }
            </Flex>
            <Space h="sm" />
            <VariableSelectCreate
                label="Output Variable"
                placeholder="Select items"
                value={dataState.outputVariable}
                handleChange={(e) => {
                    handleSelector('outputVariable', e)
                }}
            />
        </>
    )

}
export default GetValue;