import React, { useEffect, useState } from 'react';
import { Box, Radio, Select } from '@mantine/core';
import VariableSelectWrite from '../../../GlobalComponent/VariableSelectWrite';
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const TITLE_OPTIONS = [
    { value: 'title', label: 'Title' },
    { value: 'url', label: 'Url' },
]
const COMPARE_OPTIONS = [
    { value: 'equal', label: 'Equal' },
    { value: 'contain', label: 'Contain' },
]

const ActiveTab = (props) => {
    const [radioTab, setRadioTab] = useState(props?.nodeData?.data?.options?.activeTabType ?? 'filter');
    const [tabNumber, setTabNumber] = useState(props?.nodeData?.data?.options?.tabNumber ?? 1);
    const [error, setError] = useState('');
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        activeTabType : radioTab,
        title: 'url',
        comparison: 'equal',
        tabInfo: '',
        tabNumber: tabNumber
    });

    const onChangeValue = key => e => {
        if (e.type === 'change') {
            setDataState((state) => ({...state, [key]: e.target.value}))
        } else if (e.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }

    const handleChangeTabNumber = (number) => {
        
        if(!!number && Number(number.trim()) === 0 ){
            setTabNumber(1);
        }
        else{
            setTabNumber(number);
        }
    }
    
    useEffect(() => {
        dataState.activeTabType = radioTab
        dataState.tabNumber = tabNumber
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState, radioTab, tabNumber])

    return (
        <>  
            <Box component="form" mx="auto">
                <Radio.Group
                    mt="md"
                    value={radioTab}
                    name="close-tab"
                    label="Choose one"
                    spacing="sm"
                    withAsterisk
                    onChange={setRadioTab}
                >
                    <Radio value="filter" label="Filter" />
                    <Radio value="custom" label="Select tab" />
                </Radio.Group>
                {
                    radioTab === 'filter' &&
                    <>    
                        <div style={{ display: 'flex', alignItems: 'end', transition: 'width 2s', transitionTimingFunction: 'ease-out' }}>
                            <Select
                                value={dataState.title}
                                label="Title"
                                data={TITLE_OPTIONS}
                                onChange={onChangeValue('title')}
                                style={{ flex: 1 }}
                            />
                            <Select
                                style={{ marginLeft: 8, flex: 1 }}
                                value={dataState.comparison}
                                onChange={onChangeValue('comparison')}
                                label=""
                                data={COMPARE_OPTIONS}
                            />
                        </div>
                        <VariableSelectWrite
                            placeholder="Tab Info"
                            label="Tab Info"
                            dataState={dataState.tabInfo}
                            handleData={onChangeValue('tabInfo')}
                            handleSelect={onChangeValue('tabInfo')}
                        />
                    </>
                }

                {
                    radioTab === 'custom' &&
                    <>
                        <VariableNumberInput
                            label="Tab number"
                            dataState={tabNumber}
                            handleData={(e) => {
                                handleChangeTabNumber(e)
                            }}
                            
                        />
                        {
                            !!error && (<p style={{color: 'red'}}>{error}</p>)
                        }
                    </>
                }
            </Box>
           
            
        </>
    );
}

export default ActiveTab;
