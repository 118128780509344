import { ActionIcon, Popover, TextInput } from "@mantine/core";
import React, { useContext, useState } from "react";
import { IconCode } from "@tabler/icons";
import { DataFlowContext } from "../../core/context/DataFlowContext";

const VariableSelectWrite = ({ label, placeholder, dataState='', handleData=()=>{}, handleSelect=()=>{}, description, className, style, disabled, onValueChange=()=>{}, ...props }) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const dataSelect = useContext(DataFlowContext)
    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }

    const onInputChange = (event) => {
        handleData(event)
        event.newValue =event.target.value
        onValueChange(event)
    }
    const onClickItem = (event, item) => {
        handleSelect(event)
        const newValue = dataState + '${' + item.value + '}'
        event.newValue = newValue
        event.target.name = props.name
        onValueChange(event)
    }

    const rightSection = (<ActionIcon onClick={handleDropdown} variant="filled" color="yellow" ><IconCode size={16} /></ActionIcon>)
    return (
        <>
            <Popover disabled={disabled} opened={openDropdown} width="target" position="bottom" shadow="md">
                <Popover.Target>
                    <TextInput
                        {...props}
                        style={style}
                        className={className}
                        placeholder={placeholder}
                        value={dataState}
                        label={label}
                        disabled={disabled}
                        mt="xs"
                        description={description}
                        onChange={onInputChange}
                        rightSection={rightSection}
                    />
                </Popover.Target>
                <Popover.Dropdown
                    style={{ maxHeight: '40vh', overflow: 'auto' }}
                >
                    {dataSelect.nodeValue.variables.map((ele, index) => {
                        return (
                            <li
                                key={index}
                                onClick={(e) => onClickItem(e, ele)}
                                value={ele.value}
                            >
                                {ele.label}
                            </li>
                        )
                    })}
                </Popover.Dropdown>
            </Popover>
        </>
    )
}

export default VariableSelectWrite;