import { useContext, useEffect, useRef, useState } from "react";
import { ActionIcon, Button, Checkbox, Input, Radio, Tooltip } from "@mantine/core";
import { useForm } from "@mantine/form";
import VariableSelectWrite from '../../../GlobalComponent/VariableSelectWrite';
import { IconFileUpload, IconInfoCircle, IconPlus, IconTrash } from "@tabler/icons";
import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash";
import { AppSettingsContext } from '../../../../core/context/AppSettingsContext';
import { SHEET_NAME } from "./mockData";
import { versions } from "../../../../core/Navbar/automationVersion";


const DESCRIBE_KEY = {
    vi: `
    Khi chọn checkbox (Append last row) này thì script sẽ nối dữ liệu vào hàng cuối cùng trong tệp
    `,
    en: `
    If you select the checkbox "Append last row", script append data to last row in file
    `
}

const defaultState = {
    type: 'localFile',
    sheetId: '',
    filePath: '',
    cellAppend: false,
    sheetName: '',
    columns: [{id: uuid(), cellLocation: '', variable: '',}],
    fileJsonPath: '',
}


function WriteSheet(props) {
    const { settings } = useContext(AppSettingsContext)
    const defaultOptions = props?.nodeData?.data?.options ? {
        ...props?.nodeData?.data?.options,
        type: settings.autoVersion >= versions[2].autoVersion ? props.nodeData.data.options.type : 'localFile' 
    } : defaultState
    const [dataState, setDataState] = useState(defaultOptions)
    const fileRef = useRef(null)
    const fileJsonRef = useRef(null)
    const form = useForm({
        initialValues: {
            columns: props?.nodeData?.data?.options?.columns ?? defaultState.columns,
        },
    });

    const addNewRow = () => {
        const storeVariable = [...form.values.columns];
        const newColumn =  [
            ...storeVariable,
            {  id: uuid(), cellLocation: "", variable: "", type: "text" },
        ]
        form.setFieldValue('columns', newColumn);
        setDataState((state) => ({...state, columns: newColumn}))
    };

    const removeRow = (id) => {
        const newRows = form.values.columns.filter(({ id: itemId }) => itemId !== id);
        form.setFieldValue('columns', newRows);
        setDataState((state) => ({...state, columns: newRows}))
    };

    const onChangeFormValue = (key, index) => (e) => {
        const formKey = 'columns'
        const newRowValue = cloneDeep(form.values[formKey][index]);
        if (e?.type === "change") {
          newRowValue[key] = e.target.value;
        } else if (e?.type === "click") {
          const value = e.target.getAttribute("value");
          const newValue = newRowValue[key] + "${" + value + "}";
          newRowValue[key] = newValue;
        } else {
          newRowValue[key] = e;
        }
        if (key === "type") {
          newRowValue.value = "";
        }
        const newBody = cloneDeep(form.values[formKey]);
        newBody[index] = newRowValue;
        form.setFieldValue(formKey, newBody);
        setDataState((state) => ({...state, [formKey]: newBody}))
    };

    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal({...dataState, storeVariable: form.values.storeVariable })
    }, [props, dataState])

    const handleUpload = (type) => {
        if (type === 'fileJson') {
            setDataState({...dataState, fileJsonPath: fileJsonRef.current.files[0].path});
        } else if (type === 'file') {
            setDataState({...dataState, filePath: fileRef.current.files[0].path});
        }
    }
    console.log({settings})
    return ( 
        <>
            <div>
                <Radio.Group
                    label="Choose file type"
                    value={dataState.type}
                    onChange={onChangeValue("type")}
                    withAsterisk
                >
                    <Radio value="localFile" label="Local file" />
                    {
                        settings.autoVersion >= versions[2].autoVersion && (
                            <Radio value="googleSheet" label="Google Sheet" />
                        )
                    }
                </Radio.Group>
                {
                    dataState.type === "googleSheet" ? (
                        <>
                            <VariableSelectWrite
                                label="Google Spreadsheet ID"
                                placeholder="Enter text or choose variable"
                                dataState={dataState.sheetId}
                                handleData={onChangeValue('sheetId')}
                                handleSelect={onChangeValue('sheetId')}
                            />
                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                <VariableSelectWrite
                                    style={{ flex: 1 }}
                                    placeholder="JSON file"
                                    label="Credential file"
                                    dataState={dataState.fileJsonPath}
                                    // setDataState={onChangeValue('fileJsonPath')}
                                    handleData={onChangeValue('fileJsonPath')}
                                    handleSelect={onChangeValue('fileJsonPath')}
                                />
                                <Input
                                    className="custom-file-input"
                                    type="file"
                                    ref={fileJsonRef}
                                    onChange={() => handleUpload('fileJson')}
                                    icon={<IconFileUpload size={16} />}
                                    accept=".json"
                                    mt="0px"
                                    styles={(theme) => ({
                                        input: {
                                            paddingRight: "0px",
                                            '&::-webkit-file-upload-button': {
                                                visibility: 'hidden',
                                            },
                                            cursor: 'pointer',
                                        },
                                    })}
                                >
                                </Input>
                            </div>
                        </>
                        // <VariableSelectWrite
                        //     label="Google Spreadsheet ID"
                        //     placeholder="Enter text or choose variable"
                        //     dataState={dataState.sheetId}
                        //     handleData={onChangeValue('sheetId')}
                        //     handleSelect={onChangeValue('sheetId')}
                        // />
                    ) : (
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                placeholder="Enter full path or upload file"
                                label="Path to the file"
                                dataState={dataState.filePath}
                                // setDataState={onChangeValue('filePath')}
                                handleData={onChangeValue('filePath')}
                                handleSelect={onChangeValue('filePath')}
                            />
                            <Input
                                className="custom-file-input"
                                type="file"
                                ref={fileRef}
                                onChange={() => handleUpload('file')}
                                icon={<IconFileUpload size={16} />}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                mt="0px"
                                styles={(theme) => ({
                                    input: {
                                        paddingRight: "0px",
                                        '&::-webkit-file-upload-button': {
                                            visibility: 'hidden',
                                        },
                                        cursor: 'pointer',
                                    },
                                })}
                            >
                            </Input>
                        </div>
                    )
                }
                <div style={{marginTop: 10}}>
                    <div style={{ display: "flex" , alignItems: 'center'}}>
                        <label style={{fontWeight: 500, fontSize: 14}}>Sheet name (Optional)</label>
                        <Tooltip label={SHEET_NAME[settings.language] ?? SHEET_NAME.en} withArrow>
                            <ActionIcon>
                                <IconInfoCircle/>
                            </ActionIcon> 
                        </Tooltip>
                    </div>
                    <VariableSelectWrite
                        style={{marginTop: 0}}
                        placeholder="Enter text or choose variable"
                        dataState={dataState.sheetName}
                        handleData={onChangeValue('sheetName')}
                        handleSelect={onChangeValue('sheetName')}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <Checkbox
                        style={{ marginTop: 12 }}
                        checked={dataState.cellAppend}
                        label="Append last row"
                        onChange={onChangeValue('cellAppend', 'checked')}
                    />
                    <Tooltip label={DESCRIBE_KEY[settings.language] ?? DESCRIBE_KEY.en} multiline w={200} withArrow>
                        <ActionIcon>
                            <IconInfoCircle/>
                        </ActionIcon> 
                    </Tooltip>
                </div>
                {
                    form.values.columns.map(({ id, cellLocation, variable }, index) => (
                        <div key={id} style={{ display: 'flex', alignItems: 'end' }}>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                label="Cell location"
                                placeholder={dataState.cellAppend ? 'A' : 'A1'}
                                dataState={cellLocation}
                                handleData={onChangeFormValue('cellLocation', index)}
                                handleSelect={onChangeFormValue('cellLocation', index)}
                            />
                            <span style={{ marginBottom: 4, fontSize: 20, marginLeft: 12, marginRight: 12 }}>=</span>
                            <VariableSelectWrite
                                style={{ flex: 1 }}
                                label="Variable"
                                dataState={variable}
                                handleData={onChangeFormValue('variable', index)}
                                handleSelect={onChangeFormValue('variable', index)}
                            />
                            <Button
                                style={{ width: "85px", marginLeft: "12px" }}
                                color="red"
                                onClick={() => removeRow(id)}
                            >
                                <IconTrash size={20} />
                            </Button>
                        </div>
                    ))
                }
                <div style={{ textAlign: "end" }}>
                    <Button
                        rightIcon={<IconPlus size={16} />}
                        mt="sm"
                        onClick={addNewRow}
                    >
                    Add
                    </Button>
                </div>
            </div>
        </> 
    );
}

export default WriteSheet;