import React, { useState, useEffect } from 'react';
import { Box, NumberInput, Select } from '@mantine/core';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const DropdownSelector = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        elementSelect: '',
        elementOrder: 'fixed', // fixed || random
        fixedElement: 1,
        randomElementFrom: 1,
        randomElementTo: 50,
        selectedValue: '',
    });

    const handleChange = (key) => (e) => {
        if (e.type === 'change') {
            setDataState((state) => ({...state, [key]: e.target.value}))
        } else if (e.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <Box>
            <VariableSelectWrite
                label="Select element"
                placeholder="Please enter the element, such as #email input"
                dataState={dataState.elementSelect}
                handleData={handleChange('elementSelect')}
                handleSelect={handleChange('elementSelect')}
            />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <Select
                        label="Element Order"
                        data={[
                            { value: 'fixed', label: 'Fixed value' },
                            { value: 'random', label: 'Random Interval' },
                        ]}
                        onChange={handleChange('elementOrder')}
                        value={dataState.elementOrder}
                    />
                </div>
                {
                    dataState.elementOrder === 'fixed' ? (
                        <>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <VariableNumberInput
                                    style={{width:"200px"}}
                                    label="Order"
                                    dataState={dataState.fixedElement}
                                    handleData={handleChange('fixedElement')}
                                />
                            </div>
                            <div style={{ flex: 1 }}/>
                        </>
                    ) : (
                        <>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <NumberInput
                                    label="Min"
                                    onChange={handleChange('randomElementFrom')}
                                    value={dataState.randomElementFrom}
                                    min={1}
                                    />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <NumberInput
                                    min={1}
                                    label="Max"
                                    onChange={handleChange('randomElementTo')}
                                    value={dataState.randomElementTo}
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <VariableSelectWrite
                label="Selected value"
                dataState={dataState.selectedValue}
                handleData={handleChange('selectedValue')}
                handleSelect={handleChange('selectedValue')}
            />
        </Box>
    );
}

export default DropdownSelector;
