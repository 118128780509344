
import React, { useContext, useEffect, useRef, useState } from 'react';
import GlobalStyles from './components/GlobalStyles/GlobalStyles';
import AutomationFlow from './core/AutomationFlow/AutomationFlow';
import NavbarNested from './core/Navbar/Navbar';
import { useSearchParams } from "react-router-dom";
import setupInterceptors from './request/setupInterceptors';
import { NotificationsProvider } from '@mantine/notifications';
import {ScriptProvider} from "./core/context/ScriptContext";
import {DataFlowProvider} from "./core/context/DataFlowContext";
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './components/Theme/theme';
import { AppSettingsContext } from './core/context/AppSettingsContext';
import { ReactFlowProvider } from 'reactflow';

function App() {
    //get params from app when embed iframe
    const [searchParams] = useSearchParams();
    const isClone = searchParams.get('is_clone_script') === "true"
    const settingsContext = useContext(AppSettingsContext)
    const autoVersion = Number(searchParams.get('autoVersion'))

    if (searchParams.get('token')) {
        localStorage.setItem('token', searchParams.get('token'));
    }

    if (searchParams.get('base_url')) {
        localStorage.setItem('base_url', searchParams.get('base_url'));
    }

    //setup add token... to axios request
    setupInterceptors();

    //ref can use handleSave func in navbar
    const automationFlowRef = useRef();
    const handleSaveAutomationFlow = (name, saveAs) => automationFlowRef.current.handleSave(name, saveAs);
    const handleRunAutomationFlow = (uuidBrowser, browserName) => {
        automationFlowRef.current.handleRunTest(uuidBrowser, browserName)
    };

    const handleShowLogAutomationFlow = () => {
        automationFlowRef.current.handleShowLog()
    }

    const [colorScheme, setColorScheme] = useState('light');
    const [receivedMessage, setReceivedMessage] = useState('light');
    const theme = receivedMessage === 'light' ? lightTheme : darkTheme;

    useEffect(() => {
        const onMessage = (e) => {
            if (e.data.api !== "hidemium") return;
            if (e.data.type === "changeLanguage") {
                settingsContext.setSettings((settings) => ({...settings, language: e.data.content, autoVersion: autoVersion || 0 }))
                return;
            } else if (e.data.type === "remove_script_draft") {
                localStorage.removeItem("script-draft")
                return;
            }
        }
        window.addEventListener("message", onMessage);
        return () => window.removeEventListener("message", onMessage)
    }, [])

    useEffect(() => {
        const changeDarkMode = (e) => {
            if (e.data.api !== "hidemium") return;
            if (e.data.type === "changeLanguage") return;
            if (e.data.type === "remove_script_draft") return;
            if (e.data.type === "changeStore") return;
            setReceivedMessage(e.data.darkMode);
        }
        window.addEventListener("message", changeDarkMode);
        return () => window.removeEventListener("message", changeDarkMode)
    }, []);
    
    useEffect(() => {
        setColorScheme(receivedMessage === 'dark' ? 'dark' : 'light');
    }, [receivedMessage]);

    useEffect(() => {
        document.addEventListener('contextmenu', event => event.preventDefault());
        const sendClickToMain = () => {
            window.parent.postMessage({ type: "click" }, "*")
        }
        window.document.body.addEventListener("click", sendClickToMain)
        return () => window.document.body.removeEventListener("click", sendClickToMain)
    }, [])

    useEffect(() => {
        const onMessage = (e) => {
            if(e.data.api != 'hidemium') return;
            if (e.data.type === "changeStore") {
                const isCloud = e.data.content !== 'local'
                const updateId = searchParams.get('updateId')
                settingsContext.setSettings((settings) => ({...settings, isCloud: isCloud, updateId: updateId }))
                return;
            }
        }

        window.addEventListener("message", onMessage);
        return () => window.removeEventListener("message", onMessage)
    }, [])

    const handleDbClick = (newNode) => {
        automationFlowRef.current.handleDbClick(newNode);
    }

    const handleRemoveNodes = () => {
        automationFlowRef.current.handleRemoveNodes()
    }

    const handleShowVariables = () => {
        automationFlowRef.current.handleShowVariables()
    }

    return (
        <ColorSchemeProvider colorScheme={colorScheme}>
            <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
                    <GlobalStyles>
                        <NotificationsProvider position="top-right" style={{zIndex: 1001}} >
                            <div className="main">
                                <ScriptProvider>
                                <DataFlowProvider>
                                        <NavbarNested updateId={(searchParams.get('updateId') && isClone)??null} handleSaveAutomationFlow={handleSaveAutomationFlow}  handleRunAutomationFlow={handleRunAutomationFlow} 
                                        handleShowLogAutomationFlow={handleShowLogAutomationFlow} handleDbClick={handleDbClick}
                                        handleShowVariables={handleShowVariables}
                                        />
                                        <ThemeProvider theme={theme}>
                                        	<ReactFlowProvider>
	                                            <AutomationFlow updateId={searchParams.get('updateId')} ref={automationFlowRef} handleSaveAutomationFlow={handleSaveAutomationFlow}  
	                                            handleRunAutomationFlow={handleRunAutomationFlow} 
	                                            handleShowLogAutomationFlow={handleShowLogAutomationFlow}
	                                            handleRemoveNodes={handleRemoveNodes}
	                                            isDraft={searchParams.get('script_draft')}
	                                            />
                                        	 </ReactFlowProvider>
                                        </ThemeProvider>
                                </DataFlowProvider>
                                </ScriptProvider>
                            </div>
                        </NotificationsProvider>
                    </GlobalStyles>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;
