/*
    + Mỗi node sẽ được định nghĩa version để check,
    nếu ver của node nhỏ automation version của bên app đang định nghĩa thì node đó mới được xuất hiện.
    + appVersion: 'App version của node sẽ xuất hiện, bắt đầu từ 2.3.8, trước đó chưa có
    + autoVersion: Giá trị của version
*/

export const versions = {
    0: {
        appVersion: '2.3.8',
        autoVersion: 0,
    },
    // click: thêm option click down and up
    1: {
        appVersion: '',
        autoVersion: 1,
    },
    // spreadsheet google sheet, write google sheet, read mail, thêm option lưu profile tại local ở node start
    2: {
        appVersion: '2.4.1',
        autoVersion: 2,
    },
    3: {
        appVersion: '2.4.3',
        autoVersion: 3,
    }
}
