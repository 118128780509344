import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';
import { ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons';

const foreignObjectSize = 40;

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {
        strokeWidth: 1.5
    },
    markerEnd,
    data
}) {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        console.log(data)
        data?.setEdges((edges) => edges.filter((ed) => ed.id !== id));
    };

    return (
        <>
            {/* <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <path
                style={style}
                className="react-flow__edge-path nodrag nopan"
                d={edgePath}
                markerEnd={markerEnd}

            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
            >
                <ActionIcon
                    onClick={(event) => onEdgeClick(event, id)}
                    color="red"
                    size="xs"
                    radius="50%"
                    variant="light"
                >
                    <IconX size={14} />
                </ActionIcon>
            </foreignObject> */}

            <BaseEdge path={edgePath} id={id} markerEnd={markerEnd} style={style}/>
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
            >
                <ActionIcon
                    onClick={(event) => onEdgeClick(event, id)}
                    color="red"
                    size="xs"
                    radius="50%"
                    variant="light"
                >
                    <IconX size={14} />
                </ActionIcon>
            </foreignObject>
        </>
    );
}
