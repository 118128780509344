import { useMantineTheme } from "@mantine/core";

function DownloadFileDoc() {
    const theme = useMantineTheme();
    const viewDocument = (e) => {
        e.preventDefault()
        window.api.mb_ipcRenderer.sendMsg("mb_open_link_web_browser", { url: 'https://docs.hidemium.io/automation-user-manual/data/download-file'})
    }

    return ( 
        <a
            style={{ fontSize:13, color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : 'blue' }}    
            href='#'
            onClick={viewDocument}
        >
            Click here to read instructions how to make the script read the correct order in the file
        </a>
    );
}

export default DownloadFileDoc;