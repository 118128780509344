import { Button, Group, Modal, ThemeIcon, Title } from "@mantine/core";
import { IconExclamationMark } from "@tabler/icons";

function ComponentConfirm({openModal, onConfirm, onCancel}) {

    return ( 
        <>
            <Modal
                opened={openModal} 
                zIndex={1000} 
                withCloseButton={false} 
                closeOnClickOutside={false} 
                lockScroll={false}
                centered
                overflow="hidden"
                trapFocus={false}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <ThemeIcon 
                        variant="light" 
                        radius={100} size={100} 
                        color="yellow"
                    >
                        <IconExclamationMark size="5rem"/>
                    </ThemeIcon>
                </div>
                <Title size="h2" mt='xl' ta="center" >Are you sure?</Title>
                {/* <Text ta="center">You want to open it?</Text> */}
                <Group mt='xl' mb='xl' position="center" >
                    <Button onClick={onConfirm}>Yes, delete it!</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </Group>
            </Modal>
        </>
    );
}

export default ComponentConfirm;