import React, { useEffect, useRef, useState } from "react";
import { Input, Radio, Select, TextInput } from "@mantine/core";
import { IconCode, IconFileUpload } from "@tabler/icons";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";

const GetCookies = (props) => {
    const inputRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        outputVariable: "",
        domain: ""
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""});
        }  else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const handleUpload = () => {
        setDataState({...dataState, filePath: inputRef.current.files[0].path});
        console.log(inputRef.current.files[0].path)
    }

    return (
        <>
            <div>
                <VariableSelectWrite
                    label="Domain"
                    placeholder="Ex: facebook.com"
                    description="Leave blank if you want to get all browser cookies"
                    dataState={dataState.domain}
                    handleData={(e) => {
                        handleSelector('domain', e)
                    }}
                    handleSelect={(e) => {
                        const value = e.target.getAttribute('value')
                        handleSelector('domain', dataState.domain + "${" + value + "}")
                    }}
                />
                <VariableSelectCreate
                    label="Output Variable"
                    placeholder="Select items"
                    value={dataState.outputVariable}
                    handleChange={(e) => {
                        handleSelector('outputVariable', e)
                    }}
                />
            </div>
        </>
    )
}
export default GetCookies