import React, { useContext, useEffect, useState } from "react";
import { ActionIcon, TextInput, Space, Popover } from "@mantine/core";
import { IconCode } from "@tabler/icons";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";

const GetCode2FA = (props) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const data = useContext(DataFlowContext)

    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        textKey2Fa: "",
        outputVariable: "",
    });

    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }

    const handleSelector = (type, value) => {
        if (typeof value === 'string') {
            setDataState({ ...dataState, [type]: value });
        } else if (typeof value === 'object' && value === null) {
            setDataState({ ...dataState, [type]: "" });
        } else {
            setDataState({ ...dataState, [type]: value.target.value });
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const rightSection = (<ActionIcon radius="md" onClick={handleDropdown} variant="filled" color="yellow"><IconCode size={16} /></ActionIcon>)

    return (
        <>
            <Popover opened={openDropdown} width="target" position="bottom" shadow="md">
                <Popover.Target>
                    <TextInput
                        label="Enter key"
                        withAsterisk
                        value={dataState.textKey2Fa}
                        rightSection={rightSection}
                        onChange={(e) => {
                            handleSelector('textKey2Fa', e.target.value)
                            props.handleSetDataBaseModal({ textKey2Fa: e.target.value })
                        }}
                    />
                </Popover.Target>
                <Popover.Dropdown>
                    {data.nodeValue.variables.map((ele, index) => {
                        return (
                            <li
                                key={index}
                                onClick={(e) => {
                                    const value = e.target.getAttribute('value')
                                    handleSelector('textKey2Fa', dataState.textKey2Fa + "${" + value + "}")
                                }}
                                value={ele.value}
                            >
                                {ele.label}
                            </li>
                        )
                    })}
                </Popover.Dropdown>
            </Popover>
            <Space h="sm" />
            <VariableSelectCreate
                label="Output Variable"
                placeholder="Select items"
                value={dataState.outputVariable}
                handleChange={(e) => {
                    handleSelector('outputVariable', e)
                }}
            />
        </>
    )
}

export default GetCode2FA;