import { useContext, useEffect, useState } from "react";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableSelectWrite from './../../../GlobalComponent/VariableSelectWrite';
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import { Button } from "@mantine/core";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";
import { replaceVariablesWithValue } from "../../../../constants/replaceVariablesWithValue.const";

const REGEXP_TEMPLATE = [
    { value: String.raw`\d{6,6}`, name: "6 Digits" },
    {
        value: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
        name: "Link Url"
    },
    {
        value: String.raw`[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+`,
        name: "Email"
    },
]

function ExtractionInText(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        data: '',
        extractRules: '',
        saveTo: '',
        index: 0,
    })
    const [preview, setPreview] = useState(null)
    const variablesContext = useContext(DataFlowContext)
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])
    const onPreview = async () => {
        const { nodeValue } = variablesContext;
        const variables = {}
        for (const varItem of nodeValue?.variables) {
            variables[varItem.label] = varItem.data
        }
        const newDataState = replaceVariablesWithValue(dataState, nodeValue?.variables)
        const result = await window.api.ipcRenderer.invoke("test-extraction-in-text", {dataState:newDataState, variables})
        if (result) {
            setPreview({
                [dataState.saveTo]: result.foundText,
                ...result.valueWithIndex,
            })
        } else {
            setPreview("Result notfound!")
        }
    }

    return (
        <div>
            <VariableSelect
                label="Data"
                placeholder="Select"
                value={dataState.data}
                handleChange={onChangeValue('data')}
            />
            <VariableSelectWrite
                label="Extract rules"
                dataState={dataState.extractRules}
                handleData={onChangeValue('extractRules')}
                handleSelect={onChangeValue('extractRules')}
            />
            <div style={{ marginTop: 8, marginBottom: 8 }}>
                {
                    REGEXP_TEMPLATE.map(item => (
                        <Button
                            size="xs"
                            compact
                            style={{ marginRight: 8 }}
                            key={item.name}
                            onClick={() => setDataState(state => ({ ...state, extractRules: item.value }))} variant="outline"
                        >
                            {item.name}
                        </Button>
                    ))
                }
            </div>
            <VariableSelectWrite
                label="Index result"
                dataState={dataState.index || ''}
                handleData={onChangeValue('index')}
                handleSelect={onChangeValue('index')}
            />
            <VariableSelectCreate
                placeholder="Save data to variable name"
                value={dataState.saveTo}
                label="Save to"
                handleChange={onChangeValue('saveTo')}
            />
            <div style={{ marginTop: 12 }}>
                <Button size="sm" onClick={onPreview}>Test</Button>
                {
                    preview !== null && (
                        <div style={{ marginTop: 12 }}>
                            {
                                <pre style={{ backgroundColor: "#000", color: "#fff", padding: "12px", minWidth: "100%", width: "max-content", borderRadius: 12 }}>
                                    {JSON.stringify(preview, null , 4)}
                                </pre>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default ExtractionInText;
