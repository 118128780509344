import { IconBrandAndroid, IconBrandApple, IconBrandUbuntu, IconBrandWindows } from "@tabler/icons"

export const INIT_CONFIG = {
    isCreateNewProfile: false,
    configProvider: "configuration",
    os : "win",
    version : "",
    userAgent : "",
    canvas : true, // noise | off
    webGLImage : true,
    audioContext : true,
    webGLMetadata : true,
    clientRectsEnable : true,
    noiseFont : true,
    languages : "en-us,vi;q=0.9",
    resolution : "1366x768",
    proxy: "",
    command: "",
    changeFingerprint : false,
    defaultConfigId : null,
    storeMode: 'cloud'
}

export const LIST_OS = [
    { value: 'win', label: 'Windows', icon: <IconBrandWindows/> },
    { value: 'mac', label: 'MacOS', icon: <IconBrandApple/> },
    { value: 'lin', label: 'Linux', icon: <IconBrandUbuntu/> },
    { value: 'android', label: 'Android', icon: <IconBrandAndroid/> },
]

export const SWITCH_FIELDS = [
    { key: 'canvas', label: 'Canvas' },
    { key: 'webGLImage', label: 'WebGL Image' },
    { key: 'audioContext', label: 'Audio Context' },
    { key: 'webGLMetadata', label: 'WebGL Metadata' },
    { key: 'clientRectsEnable', label: 'ClientRects' },
    { key: 'noiseFont', label: 'Font' },
]

export const LIST_SCREEN_MAC = [
    {label: '1280x800', value: '1280x800', dpr: 2},
    {label: '1440x900', value: '1440x900', dpr: 2},
    {label: '1536x960', value: '1536x960', dpr: 2},
    {label: '1728x1117', value: '1728x1117', dpr: 2},
    {label: '2048x1152', value: '2048x1152', dpr: 2},
    {label: '2304x1310', value: '2304x1310', dpr: 2},
    {label: '2880x1670', value: '2880x1670', dpr: 2},
]
export const LIST_SCREEN_WIN = [ 
    {label: "1280x800", value: "1280x800"},
    {label: "1366x768", value: "1366x768"},
    {label: "1440x900", value: "1440x900"},
    {label: "1920x1080", value: "1920x1080"},
    {label: "2560x1440", value: "2560x1440"},
    {label: "3840x2160", value: "3840x2160"},
]
export const LIST_SCREEN_ANDROID = [
    {label: '213x320', value: '213x320', dpr: 1.5},
    {label: '320x533', value: '320x533', dpr: 1.5},
    {label: '320x569', value: '320x569', dpr: 1.5},
    {label: '320x658', value: '320x658', dpr: 4.5},
    {label: '353x745', value: '353x745', dpr: 3},
    {label: '360x640', value: '360x640', dpr: 3},
    {label: '360x740', value: '360x740', dpr: 3},
    {label: '360x760', value: '360x760', dpr: 3},
    {label: '360x772', value: '360x772', dpr: 4},
    {label: '360x780', value: '360x780', dpr: 3},
    {label: '360x800', value: '360x800', dpr: 3},
    {label: '360x880', value: '360x880', dpr: 3},
    {label: '360x804', value: '360x804', dpr: 4},
    {label: '384x640', value: '384x640', dpr: 2},
    {label: '384x714', value: '384x714', dpr: 2.81},
    {label: '393x786', value: '393x786', dpr: 2.75},
    {label: '400x640', value: '400x640', dpr: 2},
    {label: '412x846', value: '412x846', dpr: 3.5},
    {label: '412x869', value: '412x869', dpr: 3.5},
    {label: '412x915', value: '412x915', dpr: 2.625},
    {label: '412x737', value: '412x737', dpr: 2.63},
    {label: '424x753', value: '424x753', dpr: 1.7},
    {label: '480x854', value: '480x854', dpr: 1},
    {label: '540x960', value: '540x960', dpr: 1},
    {label: '586x820', value: '586x820', dpr: 2.625},
    {label: '720x1280', value: '720x1280', dpr: 1},
    {label: '768x1024', value: '768x1024', dpr: 2},
    {label: '980x1734', value: '980x1734', dpr: 2.25},
    {label: '1024x600', value: '1024x600', dpr: 1},
    {label: '1138x712', value: '1138x712', dpr: 2.25},
    {label: '1280x800', value: '1280x800', dpr: 1},
]