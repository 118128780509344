export const pressKey = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: 'Power', label: 'Power' },
    { value: 'Eject', label: 'Eject' },
    { value: 'Abort', label: 'Abort' },
    { value: 'Help', label: 'Help' },
    { value: 'Backspace', label: 'Backspace' },
    { value: 'Tab', label: 'Tab' },
    { value: 'Numpad5', label: 'Numpad 5' },
    { value: 'NumpadEnter', label: 'Numpad Enter' },
    { value: 'Enter', label: 'Enter' },
    // { value: '\r', label: '\r' },
    // { value: '\n', label: '\n' },
    { value: 'ShiftLeft', label: 'ShiftLeft' },
    { value: 'ShiftRight', label: 'ShiftRight' },
    { value: 'ControlLeft', label: 'ControlLeft' },
    { value: 'ControlRight', label: 'ControlRight' },
    { value: 'AltLeft', label: 'AltLeft' },
    { value: 'AltRight', label: 'AltRight' },
    { value: 'Pause', label: 'Pause' },
    { value: 'CapsLock', label: 'CapsLock' },
    { value: 'Escape', label: 'Escape' },
    { value: 'Convert', label: 'Convert' },
    { value: 'NonConvert', label: 'NonConvert' },
    { value: 'Space', label: 'Space' },
    { value: 'Numpad9', label: 'Numpad 9' },
    { value: 'PageUp', label: 'PageUp' },
    { value: 'Numpad3', label: 'Numpad 3' },
    { value: 'PageDown', label: 'PageDown' },
    { value: 'End', label: 'End' },
    { value: 'Numpad1', label: 'Numpad 1' },
    { value: 'Home', label: 'Home' },
    { value: 'Numpad7', label: 'Numpad 7' },
    { value: 'ArrowLeft', label: 'ArrowLeft' },
    { value: 'Numpad4', label: 'Numpad 4' },
    { value: 'Numpad8', label: 'Numpad 8' },
    { value: 'ArrowUp', label: 'ArrowUp' },
    { value: 'ArrowRight', label: 'ArrowRight' },
    { value: 'Numpad6', label: 'Numpad 6' },
    { value: 'Numpad2', label: 'Numpad 2' },
    { value: 'ArrowDown', label: 'ArrowDown' },
    { value: 'Select', label: 'Select' },
    { value: 'Open', label: 'Open' },
    { value: 'PrintScreen', label: 'PrintScreen' },
    { value: 'Insert', label: 'Insert' },
    { value: 'Numpad0', label: 'Numpad 0' },
    { value: 'Delete', label: 'Delete' },
    { value: 'NumpadDecimal', label: 'Numpad Decimal' },
    { value: 'Digit0', label: 'Digit 0' },
    { value: 'Digit1', label: 'Digit 1' },
    { value: 'Digit2', label: 'Digit 2' },
    { value: 'Digit3', label: 'Digit 3' },
    { value: 'Digit4', label: 'Digit 4' },
    { value: 'Digit5', label: 'Digit 5' },
    { value: 'Digit6', label: 'Digit 6' },
    { value: 'Digit7', label: 'Digit 7' },
    { value: 'Digit8', label: 'Digit 8' },
    { value: 'Digit9', label: 'Digit 9' },
    { value: 'KeyA', label: 'Key A' },
    { value: 'KeyB', label: 'Key B' },
    { value: 'KeyC', label: 'Key C' },
    { value: 'KeyD', label: 'Key D' },
    { value: 'KeyE', label: 'Key E' },
    { value: 'KeyF', label: 'Key F' },
    { value: 'KeyG', label: 'Key G' },
    { value: 'KeyH', label: 'Key H' },
    { value: 'KeyI', label: 'Key I' }, 
    { value: 'KeyJ', label: 'Key J' },
    { value: 'KeyK', label: 'Key K' },
    { value: 'KeyL', label: 'Key L' },
    { value: 'KeyM', label: 'Key M' },
    { value: 'KeyN', label: 'Key N' },
    { value: 'KeyO', label: 'Key O' },
    { value: 'KeyP', label: 'Key P' },
    { value: 'KeyQ', label: 'Key Q' },
    { value: 'KeyR', label: 'Key R' },
    { value: 'KeyS', label: 'Key S' },
    { value: 'KeyT', label: 'Key T' },
    { value: 'KeyU', label: 'Key U' },
    { value: 'KeyV', label: 'Key V' },
    { value: 'KeyW', label: 'Key W' },
    { value: 'KeyX', label: 'Key X' },
    { value: 'KeyY', label: 'Key Y' },
    { value: 'KeyZ', label: 'Key Z' },
    { value: 'MetaLeft', label: 'MetaLeft' },
    { value: 'MetaRight', label: 'MetaRight' },
    { value: 'ContextMenu', label: 'ContextMenu' },
    { value: 'NumpadMultiply', label: 'Numpad Multiply' },
    { value: 'NumpadAdd', label: 'Numpad Add' },
    { value: 'NumpadSubtract', label: 'Numpad Subtract' },
    { value: 'NumpadDivide', label: 'Numpad Divide' },
    { value: 'F1', label: 'F1' },
    { value: 'F2', label: 'F2' },
    { value: 'F3', label: 'F3' },
    { value: 'F4', label: 'F4' },
    { value: 'F5', label: 'F5' },
    { value: 'F6', label: 'F6' },
    { value: 'F7', label: 'F7' },
    { value: 'F8', label: 'F8' },
    { value: 'F9', label: 'F9' },
    { value: 'F10', label: 'F10' },
    { value: 'F11', label: 'F11' },
    { value: 'F12', label: 'F12' },
    { value: 'F13', label: 'F13' },
    { value: 'F14', label: 'F14' },
    { value: 'F15', label: 'F15' },
    { value: 'F16', label: 'F16' },
    { value: 'F17', label: 'F17' },
    { value: 'F18', label: 'F18' },
    { value: 'F19', label: 'F19' },
    { value: 'F20', label: 'F20' },
    { value: 'F21', label: 'F21' },
    { value: 'F22', label: 'F22' },
    { value: 'F23', label: 'F23' },
    { value: 'F24', label: 'F24' },
    { value: 'NumLock', label: 'NumLock' },
    { value: 'ScrollLock', label: 'ScrollLock' },
    { value: 'AudioVolumeMute', label: 'AudioVolumeMute' },
    { value: 'AudioVolumeDown', label: 'AudioVolumeDown' },
    { value: 'AudioVolumeUp', label: 'AudioVolumeUp' },
    { value: 'MediaTrackNext', label: 'MediaTrackNext' },
    { value: 'MediaTrackPrevious', label: 'MediaTrackPrevious' },
    { value: 'MediaStop', label: 'MediaStop' },
    { value: 'MediaPlayPause', label: 'MediaPlayPause' },
    { value: 'Semicolon', label: 'Semicolon' },
    { value: 'Equal', label: 'Equal' },
    { value: 'NumpadEqual', label: 'Numpad Equal' },
    { value: 'Comma', label: 'Comma' },
    { value: 'Minus', label: 'Minus' },
    { value: 'Period', label: 'Period' },
    { value: 'Slash', label: 'Slash' },
    { value: 'Backquote', label: 'Backquote' },
    { value: 'BracketLeft', label: 'BracketLeft' },
    { value: 'Backslash', label: 'Backslash' },
    { value: 'BracketRight', label: 'BracketRight' },
    { value: 'Quote', label: 'Quote' },
    { value: 'AltGraph', label: 'AltGraph' },
    { value: 'Props', label: 'Props' },
    { value: 'Cancel', label: 'Cancel' },
    { value: 'Clear', label: 'Clear' },
    { value: 'Shift', label: 'Shift' },
    { value: 'Control', label: 'Control' },
    { value: 'Alt', label: 'Alt' },
    { value: 'Accept', label: 'Accept' },
    { value: 'ModeChange', label: 'ModeChange' },
    // { value: ' ', label: ' ' },
    { value: 'Print', label: 'Print' },
    { value: 'Execute', label: 'Execute' },
    { value: '\u0000', label: '\u0000' },
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
    { value: 'd', label: 'd' },
    { value: 'e', label: 'e' },
    { value: 'f', label: 'f' },
    { value: 'g', label: 'g' },
    { value: 'h', label: 'h' },
    { value: 'i', label: 'i' },
    { value: 'j', label: 'j' },
    { value: 'k', label: 'k' },
    { value: 'l', label: 'l' },
    { value: 'm', label: 'm' },
    { value: 'n', label: 'n' },
    { value: 'o', label: 'o' },
    { value: 'p', label: 'p' },
    { value: 'q', label: 'q' },
    { value: 'r', label: 'r' },
    { value: 's', label: 's' },
    { value: 't', label: 't' },
    { value: 'u', label: 'u' },
    { value: 'v', label: 'v' },
    { value: 'w', label: 'w' },
    { value: 'x', label: 'x' },
    { value: 'y', label: 'y' },
    { value: 'z', label: 'z' },
    { value: 'Meta', label: 'Meta' },
    { value: '*', label: '*' },
    { value: '+', label: '+' },
    { value: '-', label: '-' },
    { value: '/', label: '/' },
    { value: ';', label: ';' },
    { value: '=', label: '=' },
    { value: ',', label: ',' },
    { value: '.', label: '.' },
    { value: '`', label: '`' },
    { value: '[', label: '[' },
    { value: '\\', label: '\\' },
    { value: ']', label: ']' },
    { value: "'", label: "'" },
    { value: 'Attn', label: 'Attn' },
    { value: 'CrSel', label: 'CrSel' },
    { value: 'ExSel', label: 'ExSel' },
    { value: 'EraseEof', label: 'EraseEof' },
    { value: 'Play', label: 'Play' },
    { value: 'ZoomOut', label: 'ZoomOut' },
    { value: ')', label: ')' },
    { value: '!', label: '!' },
    { value: '@', label: '@' },
    { value: '#', label: '#' },
    { value: '$', label: '$' },
    { value: '%', label: '%' },
    { value: '^', label: '^' },
    { value: '&', label: '&' },
    { value: '(', label: '(' },
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' },
    { value: 'H', label: 'H' },
    { value: 'I', label: 'I' },
    { value: 'J', label: 'J' },
    { value: 'K', label: 'K' },
    { value: 'L', label: 'L' },
    { value: 'M', label: 'M' },
    { value: 'N', label: 'N' },
    { value: 'O', label: 'O' },
    { value: 'P', label: 'P' },
    { value: 'Q', label: 'Q' },
    { value: 'R', label: 'R' },
    { value: 'S', label: 'S' },
    { value: 'T', label: 'T' },
    { value: 'U', label: 'U' },
    { value: 'V', label: 'V' },
    { value: 'W', label: 'W' },
    { value: 'X', label: 'X' },
    { value: 'Y', label: 'Y' },
    { value: 'Z', label: 'Z' },
    { value: ':', label: ':' },
    { value: '<', label: '<' },
    { value: '_', label: '_' },
    { value: '>', label: '>' },
    { value: '?', label: '?' },
    { value: '~', label: '~' },
    { value: '{', label: '{' },
    { value: '|', label: '|' },
    { value: '}', label: '}' },
    { value: '"', label: '"' },
    { value: 'SoftLeft', label: 'SoftLeft' },
    { value: 'SoftRight', label: 'SoftRight' },
    { value: 'Camera', label: 'Camera' },
    { value: 'Call', label: 'Call' },
    { value: 'EndCall', label: 'EndCall' },
    { value: 'VolumeDown', label: 'VolumeDown' },
    { value: 'VolumeUp', label: 'VolumeUp' }
]
