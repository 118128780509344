import React, { useContext, useEffect, useRef, useState } from "react";
import { ActionIcon, Button, Code, Grid, Popover, Radio, Select, Spoiler, Text, TextInput, ScrollArea } from "@mantine/core";
import { Prism } from '@mantine/prism';
import { IconCode, IconPlus, IconTrash } from "@tabler/icons";
import { DataFlowContext } from "../../../../core/context/DataFlowContext";
import { v4 as uuid } from 'uuid';
import VariableTextarea from "../../../GlobalComponent/VariableTextarea"
import FormDataBody from "./FormDataBody";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import styles from "../../../GlobalStyles/styleInputSearch.module.css"

const responseBodyMapping = `
response: {
    "status": "ok",
    "result": {
        "field": "value",
    }
}
`

const responseHeadersMapping = `
{
    "Server": "nginx,
    "Vary": "Accept, Origin,
    "Content-Type": "application/json,
    ...
}
`

const responseCookiesMapping = `
{
    "i": {
        "path": "/",
        "value": "Uy/dClqOS6QjiEcSblablabla",
        "domain": "ya.ru",
        "secure": true,
        "comment": "",
        "expires": "Sat, 15-Dec-2029 23:37:51 GMT",
        "httponly": true,
        ...
    },
    "yp": {
        "path": "/",
        "value": "1579304272.ygu.1",
        "domain": "ya.ru",
        "expires": "Sat, 15-Dec-2029 23:37:51 GMT",
        ...
    }
}
`

const SetHttpRequest = (props) => {
    const [url, setUrl] = useState(props?.nodeData?.data?.options?.url ?? "")
    const [method, setMethod] = useState(props?.nodeData?.data?.options?.method ?? "POST")
    const [headers, setHeaders] = useState(props?.nodeData?.data?.options?.headers ?? "")
    const [cookies, setCookies] = useState(props?.nodeData?.data?.options?.cookies ?? "")
    const [contentType, setContentType] = useState(props?.nodeData?.data?.options?.contentType ?? "")
    const [dataString, setDataString] = useState(props?.nodeData?.data?.options?.dataString ?? "")
    const [outputBodyVariables, setOutputBodyVariables] = useState(props?.nodeData?.data?.options?.outputBodyVariables ?? "")
    const [outputHeadersVariables, setOutputHeadersVariables] = useState(props?.nodeData?.data?.options?.outputHeadersVariables ?? "")
    const [outputCookiesVariables, setOutputCookiesVariables] = useState(props?.nodeData?.data?.options?.outputCookiesVariables ?? "")
    const [openDropdown, setOpenDropdown] = useState(false)
    const data = useContext(DataFlowContext)
    const formBodyRef = useRef()

    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }
    const handleInputValue = (value) => {
        setUrl(value)
    }
    const handleSetValue = (e) => {
        const value = e.target.getAttribute('value')
        setUrl((pre) => pre + "${" + value + "}")
    }

    // handle select content type
    const handleSelectContentType = (value) => {
        formBodyRef?.current?.onChangeContentType(value)
        setContentType(value)
    }

    // handle select radio method
    const handleRadioMethod = (value) => {
        setMethod(value)
    }

    // handle change text area data string
    const handleChangeDataString = (value) => {
        setDataString(value)
    }

    // handle delete input headers
    const handleDeleteHeaders = (id) => {
        setHeaders((pre) =>
            pre.filter(header => header.id !== id))
    }

    // handle delete input cookies
    const handleDeleteCookies = (id) => {
        setCookies((pre) => pre.filter(cookie => cookie.id !== id))
    }

    // handle delete input body variable
    const handleDeleteBodyVariable = (id) => {
        setOutputBodyVariables((pre) => pre.filter(bodyVariable => bodyVariable.id !== id))

    }

    // handle delete input header variable
    const handleDeleteHeaderVariable = (id) => {
        setOutputHeadersVariables((pre) => pre.filter(headerVariable => headerVariable.id !== id))

    }

    // handle delete input cookie variable
    const handleDeleteCookieVariable = (id) => {
        setOutputCookiesVariables((pre) => pre.filter(cookieVariable => cookieVariable.id !== id))
    }

    // handle add input headers
    const handleAddHeaders = () => {
        setHeaders((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    // handle add input cookies
    const handleAddCookies = () => {
        setCookies((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    // handle add input body variable
    const handleAddBodyVariable = () => {
        setOutputBodyVariables((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    // handle add input header variable
    const handleAddHeaderVariable = () => {
        setOutputHeadersVariables((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    // handle add input header variable
    const handleAddCookieVariable = () => {
        setOutputCookiesVariables((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    // handle change input headers
    const handleChangeHeaders = (id) => (e) => {
        const { name } = e.target
        const value = e.newValue || e.target.value
        let result = headers.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setHeaders(result)
    }
    // handle change input cookies
    const handleChangeCookies = (id) => (e) => {
        const { name } = e.target
        const value = e.newValue || e.target.value
        let result = cookies.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setCookies(result)
    }

    // handle change input key body variable
    const handleChangeBodyVariables = (id) => (e) => {
        const { name, value } = e.target
        let result = outputBodyVariables.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOutputBodyVariables(result)
    }

    // handle change select value body variable
    const handleSelectBodyVariable = (value, id) => {
        let result = outputBodyVariables.map((item) => {
            if (item.id === id) {
                return { ...item, "value": value }
            } else {
                return item
            }
        })
        setOutputBodyVariables(result)
    }

    // handle change input key header variable
    const handleChangeHeaderVariable = (id) => (e) => {
        const { name, value } = e.target
        let result = outputHeadersVariables.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOutputHeadersVariables(result)
    }

    // handle change select value header variable
    const handleSelectHeaderVariable = (value, id) => {
        let result = outputHeadersVariables.map((item) => {
            if (item.id === id) {
                return { ...item, "value": value }
            } else {
                return item
            }
        })
        setOutputHeadersVariables(result)
    }

    // handle change input key cookie variable
    const handleChangeCookieVariable = (id) => (e) => {
        const { name, value } = e.target
        let result = outputCookiesVariables.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOutputCookiesVariables(result)
    }

    // handle change select value cookie variable
    const handleSelectCookieVariable = (value, id) => {
        let result = outputCookiesVariables.map((item) => {
            if (item.id === id) {
                return { ...item, "value": value }
            } else {
                return item
            }
        })
        setOutputCookiesVariables(result)
    }


    // save node data
    const handleSetDataBaseModal = props.handleSetDataBaseModal;
    useEffect(() => {
        const options = {
            contentType: contentType,
            url: url,
            method: method,
            headers: headers,
            cookies: cookies,
            dataString: dataString,
            outputBodyVariables: outputBodyVariables,
            outputHeadersVariables: outputHeadersVariables,
            outputCookiesVariables: outputCookiesVariables
        }
        handleSetDataBaseModal(options)
    }, [handleSetDataBaseModal, url, headers, cookies, contentType, method, dataString, outputBodyVariables, outputHeadersVariables, outputCookiesVariables])

    const newData = data.nodeValue.variables.filter(variable => variable.label !== "")
    const leftSection = (<ActionIcon radius="md"><IconCode size={16} /></ActionIcon>)
    const rightSection = (<ActionIcon radius="md" onClick={handleDropdown} variant="filled" color="yellow"><IconCode size={16} /></ActionIcon>)

    const onChangeBody = e => {
        if (e?.type === 'change') {
            setDataString(e.target.value)
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataString + "${" + value + "}"
            setDataString(newValue)
        } else {
            setDataString(e)
        }
    }

    return (
        <>
            <Popover opened={openDropdown} width="target" position="bottom" shadow="md">
                <Popover.Target>
                    <TextInput
                        placeholder="URL"
                        value={url}
                        label="Enter URL"
                        onChange={(event) => handleInputValue(event.currentTarget.value)}
                        rightSection={rightSection}
                    />
                </Popover.Target>
                <Popover.Dropdown>
                <div style={{ maxHeight: '220px', display: 'flex'}}>
                <ScrollArea style={{ flex: '1'}}>
                <div className={styles.select_itemsWrapper} style={{ flexDirection: 'column' }}>
                    {data.nodeValue.variables.map((ele, index) => {
                        return (
                            <li
                                key={index}
                                onClick={(e) => handleSetValue(e)}
                                value={ele.value}
                                className={styles.select_item}
                            >
                                {ele.label}
                            </li>
                        )
                    })}
                </div>
                </ScrollArea>
                </div>
                </Popover.Dropdown>
            </Popover>
            <Radio.Group
                label="Request method"
                mt="xs"
                value={method}
                onChange={handleRadioMethod}
            >
                <Radio value="GET" label="GET" />
                <Radio value="POST" label="POST" />
                <Radio value="PUT" label="PUT" />
                <Radio value="DELETE" label="DELETE" />
            </Radio.Group>
            <Text
                fw={700}
                mt="xs"
                fz={16}
            >
                Headers
            </Text>
            {
                headers.map((ele, index) => {
                    return (
                        <Grid
                            grow
                            align="flex-end"
                            key={index}
                            mt="xs"
                        >
                            <Grid.Col span={5}>
                                <TextInput
                                    label="Name"
                                    placeholder="Name"
                                    name="key"
                                    value={ele.key}
                                    onChange={handleChangeHeaders(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={5} >
                                <VariableSelectWrite
                                    dataState={ele.value}
                                    name="value"
                                    placeholder="Value"
                                    onValueChange={handleChangeHeaders(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Button
                                    w="100%"
                                    color="red"
                                    onClick={() => handleDeleteHeaders(ele.id)}
                                >
                                    <IconTrash size={20} />
                                </Button>
                            </Grid.Col>
                        </Grid>
                    )
                })
            }
            <Button
                rightIcon={<IconPlus size={16} />}
                mt="sm"
                onClick={handleAddHeaders}
            >
                Add
            </Button>
            <Text
                fw={700}
                mt="xs"
                fz={16}
            >
                Cookies
            </Text>
            {
                cookies.map((ele, index) => {
                    return (
                        <Grid
                            grow
                            align="flex-end"
                            key={index}
                            mt="xs"

                        >
                            <Grid.Col span={5}>
                                <TextInput
                                    label="Name"
                                    placeholder="Name"
                                    name="key"
                                    value={ele.key}
                                    onChange={handleChangeCookies(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={5} >
                                <VariableSelectWrite
                                    dataState={ele.value}
                                    name="value"
                                    placeholder="Value"
                                    onValueChange={handleChangeCookies(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Button
                                    w="100%"
                                    color="red"
                                    onClick={() => handleDeleteCookies(ele.id)}
                                >
                                    <IconTrash size={20} />
                                </Button>
                            </Grid.Col>
                        </Grid>
                    )
                })
            }
            <Button
                rightIcon={<IconPlus size={16} />}
                mt="sm"
                onClick={handleAddCookies}
            >
                Add
            </Button>
            {
                (method !== 'GET' ? (
                    <>
                        <Select
                            mt="xs"
                            label="Select content Type"
                            defaultValue="application/json"
                            value={contentType}
                            onChange={handleSelectContentType}
                            data={[
                                { value: "application/json", label: "JSON" },
                                { value: "text/plain", label: "Text" },
                                { value: "text/html", label: "HTML" },
                                { value: "text/xml", label: "XML" },
                                { value: "application/x-www-form-urlencoded", label: "Form URLEncoded" },
                                { value: "multipart/form-data", label: "Form Data" },
                                { value: "custom", label: "Custom" },
                            ]}
                        />
                        {
                            (contentType !== 'application/x-www-form-urlencoded' && contentType !== 'multipart/form-data') ? (
                                <VariableTextarea
                                    placeholder="Enter request body"
                                    label={'Body - ' + contentType}
                                    dataState={dataString}
                                    handleData={onChangeBody}
                                    handleSelect={onChangeBody}
                                />
                            ): (null)
                        }
                        <FormDataBody
                            ref={formBodyRef}
                            contentType={contentType}
                            onChange={onChangeBody}
                            dataState={dataString}
                        />
                    </>
                ) : (null))
            }
            <Text
                fw={700}
                mt="lg"
                fz={16}
            >
                Response body mapping
            </Text>
            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                If the server response is in JSON format, you can store all or part of the response in variables using mappings. The entire response is available via the <Code>body</Code> path. For example: the value "value" can be extracted from JSON data using the path
                <Code>response.result.field</Code>.
                <Prism language="tsx" >{responseBodyMapping}</Prism>
            </Spoiler>
            {
                outputBodyVariables.map((ele, index) => {
                    return (
                        <Grid
                            grow
                            align="flex-end"
                            key={index}
                            mt="xs"
                        >
                            <Grid.Col span={5}>
                                <TextInput
                                    label="JSON path"
                                    placeholder="Path"
                                    name="key"
                                    value={ele.key}
                                    onChange={handleChangeBodyVariables(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={5} >
                                <Select
                                    label="Output Variable"
                                    data={newData}
                                    placeholder="Select Variable"
                                    nothingFound="No created variables"
                                    searchable
                                    value={ele.value}
                                    onChange={(value) => handleSelectBodyVariable(value, ele.id)}
                                    creatable
                                    getCreateLabel={(query) => `+ Create ${query}`}
                                    onCreate={(query) => {
                                        const item = { id: uuid(), value: query, label: query, data: "", name: "" };
                                        data.setNodeValue({
                                            variables: [...data.nodeValue.variables, item]
                                        })
                                        return item;
                                    }}
                                    icon={leftSection}
                                />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Button
                                    w="100%"
                                    color="red"
                                    onClick={() => handleDeleteBodyVariable(ele.id)}
                                >
                                    <IconTrash size={20} />
                                </Button>
                            </Grid.Col>
                        </Grid>
                    )
                })
            }
            <Button
                rightIcon={<IconPlus size={16} />}
                mt="sm"
                onClick={handleAddBodyVariable}
            >
                Add
            </Button>
            <Text
                fw={700}
                mt="xs"
                fz={16}
            >
                Response headers mapping
            </Text>
            <Spoiler maxHeight={80} showLabel="Show more" hideLabel="Hide">
                Response headers will be converted to JSON-format. For example:
                <Prism language="tsx" >{responseHeadersMapping}</Prism>
            </Spoiler>
            {
                outputHeadersVariables.map((ele, index) => {
                    return (
                        <Grid
                            grow
                            align="flex-end"
                            key={index}
                            mt="xs"
                        >
                            <Grid.Col span={5}>
                                <TextInput
                                    label="JSON path"
                                    placeholder="Path"
                                    name="key"
                                    value={ele.key}
                                    onChange={handleChangeHeaderVariable(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={5} >
                                <Select
                                    label="Output Variable"
                                    data={newData}
                                    placeholder="Select Variable"
                                    nothingFound="No created variables"
                                    searchable
                                    value={ele.value}
                                    onChange={(value) => handleSelectHeaderVariable(value, ele.id)}
                                    creatable
                                    getCreateLabel={(query) => `+ Create ${query}`}
                                    onCreate={(query) => {
                                        const item = { id: uuid(), value: query, label: query, data: "", name: "" };
                                        data.setNodeValue({
                                            variables: [...data.nodeValue.variables, item]
                                        })
                                        return item;
                                    }}
                                    icon={leftSection}
                                />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Button
                                    w="100%"
                                    color="red"
                                    onClick={() => handleDeleteHeaderVariable(ele.id)}
                                >
                                    <IconTrash size={20} />
                                </Button>
                            </Grid.Col>
                        </Grid>
                    )
                })
            }
            <Button
                rightIcon={<IconPlus size={16} />}
                mt="sm"
                onClick={handleAddHeaderVariable}
            >
                Add
            </Button>
            <Text
                fw={700}
                mt="xs"
                fz={16}
            >
                Response cookies mapping
            </Text>
            <Spoiler maxHeight={80} showLabel="Show more" hideLabel="Hide">
                Response cookies will be converted to JSON-format. For example:
                <Prism language="tsx" >{responseCookiesMapping}</Prism>
            </Spoiler>
            {
                outputCookiesVariables.map((ele, index) => {
                    return (
                        <Grid
                            grow
                            align="flex-end"
                            key={index}
                            mt="xs"
                        >
                            <Grid.Col span={5}>
                                <TextInput
                                    label="JSON path"
                                    placeholder="Path"
                                    name="key"
                                    value={ele.key}
                                    onChange={handleChangeCookieVariable(ele.id)}
                                />
                            </Grid.Col>
                            <Grid.Col span={5} >
                                <Select
                                    label="Output Variable"
                                    data={newData}
                                    placeholder="Select Variable"
                                    nothingFound="No created variables"
                                    searchable
                                    value={ele.value}
                                    onChange={(value) => handleSelectCookieVariable(value, ele.id)}
                                    creatable
                                    getCreateLabel={(query) => `+ Create ${query}`}
                                    onCreate={(query) => {
                                        const item = { id: uuid(), value: query, label: query, data: "", name: "" };
                                        data.setNodeValue({
                                            variables: [...data.nodeValue.variables, item]
                                        })
                                        return item;
                                    }}
                                    icon={leftSection}
                                />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Button
                                    w="100%"
                                    color="red"
                                    onClick={() => handleDeleteCookieVariable(ele.id)}
                                >
                                    <IconTrash size={20} />
                                </Button>
                            </Grid.Col>
                        </Grid>
                    )
                })
            }
            <Button
                rightIcon={<IconPlus size={16} />}
                mt="sm"
                onClick={handleAddCookieVariable}
            >
                Add
            </Button>
        </>
    )

}
export default SetHttpRequest
