import * as Icons from '@tabler/icons';

const LoadIcon = ({ icon, ...props }) => {
    
    const Icon = Icons[icon];

    return Icon ? <Icon {...props} /> : "";
}

export default LoadIcon;
