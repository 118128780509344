import React, { useEffect, useContext } from "react";
import { IconPlus } from "@tabler/icons";
import { Button, Flex, Group, Text, TextInput } from "@mantine/core";
import { v4 as uuid } from 'uuid';
import {DataFlowContext} from "../../../../core/context/DataFlowContext";

const Variables = (props) => {
    const data = useContext(DataFlowContext)
    // save node
    const handleSetDataBaseModal = props.handleSetDataBaseModal;
    useEffect(() => {
        handleSetDataBaseModal({ variables: data.nodeValue.variables })
    }, [data.nodeValue.variables, handleSetDataBaseModal])

    console.log(data.nodeValue.variables)
    // add input variables
    const handleAddVariables = () => {
        const item = {
            id: uuid(),
            label: "",
            value: "",
            data: "",
        }
        data.setNodeValue({
            variables: [
                ...data.nodeValue.variables, item
            ]
        })
    }

    // delete input variables
    const handleRemoveVariables = (id) => {
        const result = data.nodeValue.variables.filter(variable => variable.id !== id)
        data.setNodeValue({
            variables: result
        })
    }

    // change input variables
    const handleSetNameVariables = (id) => (e) => {
        const { name, value } = e.target
        let result = data.nodeValue.variables.map((item, i) => {
            if (item.id === id) {
                return { ...item, [name]: value, 'value': value }
            } else {
                return item
            }
        })
        data.setNodeValue({
            variables: result
        })
    }

    const handleSetValueVariables = (id) => (e) => {
        const { name, value } = e.target
        let result = data.nodeValue.variables.map((item, i) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        data.setNodeValue({
            variables: result
        })
    }

    return (
        <>
            {data.nodeValue.variables.length > 0 ? (
                data.nodeValue.variables.map((element, index) => {
                    return (
                        (element.type === "disabled") ? (
                            <Flex
                                key={index}
                                mt="xs"
                                direction={{ base: 'column', sm: 'row' }}
                                gap={{ base: 'sm', sm: 'lg' }}
                                justify={{ sm: 'center' }}
                            >
                                <TextInput
                                    withAsterisk
                                    value=""
                                    placeholder={element.label}
                                    disabled
                                />
                                <Text lh="2.4">&#61;</Text>
                                <TextInput
                                    withAsterisk
                                    value=""
                                    placeholder="Default"
                                    disabled
                                />
                                <Button.Group>
                                    <Button variant="default" onClick={() => handleRemoveVariables(element.id)}>&#8722;</Button>
                                    <Button variant="default" onClick={handleAddVariables}>&#43;</Button>
                                </Button.Group>
                            </Flex>
                        ) : (
                            <Flex
                                key={index}
                                mt="xs"
                                direction={{ base: 'column', sm: 'row' }}
                                gap={{ base: 'sm', sm: 'lg' }}
                                justify={{ sm: 'center' }}
                            >
                                <TextInput
                                    withAsterisk
                                    placeholder="Name"
                                    name="label"
                                    value={element.label}
                                    onChange={handleSetNameVariables(element.id)}
                                />
                                <Text lh="2.4">&#61;</Text>
                                <TextInput
                                    withAsterisk
                                    placeholder="Value"
                                    name="data"
                                    value={element.data}
                                    onChange={handleSetValueVariables(element.id)}
                                />
                                <Button.Group>
                                    <Button variant="default" onClick={() => handleRemoveVariables(element.id)}>&#8722;</Button>
                                    <Button variant="default" onClick={handleAddVariables}>&#43;</Button>
                                </Button.Group>
                            </Flex>)
                    )
                })
            ) : (
                <Group position="center">
                    <Button
                        fullWidth
                        variant="default"
                        leftIcon={<IconPlus size={14} />}
                        onClick={handleAddVariables}
                    >
                        Create
                    </Button>
                </Group>
            )}
        </>
    )
}
export default Variables