import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  contextMenu: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    boxShadow: '10px 19px 20px rgba(0, 0, 0, 10%)',
    position: 'absolute',
    zIndex: '10',
    borderRadius: '4px',
  },
  spinContainer: {
    listStyle: 'none',
    li: {
      padding: '5px 12px !important',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      }
    }
  },
  textShortcut: {
    color: theme.colors.dark[0],
  }
}))