import React, { useState, useEffect } from 'react';
import {Box, Flex, NumberInput, Radio, Select} from '@mantine/core';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const Scroll = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        selectorType: 'selector',
        elementSelect: "",
        fixedElement: 1,
        deltaX: 0,
        deltaY: 0
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <Box>
            <Radio.Group
                value={dataState.selectorType}
                label="Choose type"
                onChange={(e) => {
                    handleSelector('selectorType', e)
                }}
                name="element-exists"
            >
                <Radio value="selector" label="Selector" />
                <Radio value="coordinates" label="Coordinates" />
            </Radio.Group>
            {dataState.selectorType === 'selector' &&
                <>
                    <VariableSelectWrite
                        style={{marginBottom:"10px"}}
                        dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                        setDataState={handleSelector}
                        handleData={(e) => {
                            handleSelector('elementSelect', e)
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('elementSelect', dataState.elementSelect + "${" + value + "}")
                        }}
                    />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <VariableNumberInput
                            style={{width:"200px"}}
                            label="Element order"
                            dataState={dataState.fixedElement}
                            handleData={(e) => {
                                handleSelector('fixedElement', e)
                            }}
                        />
                    </Flex>
                </>
            }
            {dataState.selectorType === 'coordinates' &&
                <>
                    <VariableSelectWrite
                        label="Enter X coordinate"
                        dataState={dataState.deltaX}
                        handleData={(e) => {
                            handleSelector('deltaX', e);
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('deltaX', dataState.deltaX + "${" + value + "}");
                        }}
                    />
                    <VariableSelectWrite
                        label="Enter Y coordinate"
                        dataState={dataState.deltaY}
                        handleData={(e) => {
                            handleSelector('deltaY', e);
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('deltaY', dataState.deltaY + "${" + value + "}");
                        }}
                    />
                </>
            }
        </Box>
    );
}

export default Scroll;
