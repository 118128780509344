import { showNotification } from '@mantine/notifications';
import { IconX, IconCheck } from '@tabler/icons';

export const systemNotifications = ({ type, message }) => {
    const notiBody = { message: message ?? '...' }

    if (type === "error") {
        notiBody.icon = <IconX />;
        notiBody.color = 'red';
    } else {
        notiBody.icon = <IconCheck />;
        notiBody.color = 'green';
    }

    showNotification(notiBody)
}

export const apiNotifications = (res) => {

    if (res.hasOwnProperty('data')) {
        systemNotifications({ message: res.data.title });
        return
    }

    const data = res.response.data;
    if (data.content && Array.isArray(data.content) && data.content.length > 0) {
        data.content.forEach(function (element) {
            systemNotifications({ type: "error", message: element });
        });
        return
    }

    systemNotifications({ type: "error", message: data.title });
}