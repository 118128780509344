import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Button, Select, FileInput, Text, Input } from "@mantine/core";
import { IconFileUpload, IconPlus, IconTrash } from "@tabler/icons";
import { useForm } from "@mantine/form";
import { cloneDeep, isArray } from "lodash";
import { v4 as uuid } from "uuid";

import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

function FormDataBody({ contentType, onChange, dataState }, ref) {
  const inputRef = useRef(null)
  const form = useForm({
    initialValues: {
      body: dataState,
    },
  });
  const onChangeValue = (key, index) => (e) => {
    const newRowValue = cloneDeep(form.values.body[index]);
    if (e?.type === "change") {
      newRowValue[key] = e.target.value;
    } else if (e?.type === "click") {
      const value = e.target.getAttribute("value");
      const newValue = newRowValue[key] + "${" + value + "}";
      newRowValue[key] = newValue;
    } else {
      newRowValue[key] = e;
    }
    if (key === "type") {
      newRowValue.value = "";
    }
    const newBody = cloneDeep(form.values.body);
    newBody[index] = newRowValue;
    form.setFieldValue("body", newBody);
  };
  const addNewRow = () => {
    const body = [...form.values.body];
    form.setFieldValue("body", [
      ...body,
      { name: "", value: "", type: "text", id: uuid() },
    ]);
  };
  const removeRow = (id) => {
    const newRows = form.values.body.filter(({ id: itemId }) => itemId !== id);
    form.setFieldValue("body", newRows);
  };

  useImperativeHandle(ref, () => ({
    onChangeContentType: (type) => {
      if ((type === "multipart/form-data" || type === "application/x-www-form-urlencoded") && !isArray(form.values.body)) {
        form.setFieldValue("body", [{ name: "", value: "", type: "text", id: uuid() },]);
      }
      if (type === "application/x-www-form-urlencoded" && isArray(form.values.body)) {
        const newBody = cloneDeep(form.values.body);
        newBody.forEach((item) => {
          if (item.type !== "text") {
            item.type = "text";
            item.value = "";
          }
        });
        form.setFieldValue("body", newBody);
      } else if (type !== "multipart/form-data" && type !== "application/x-www-form-urlencoded") {
        form.setFieldValue("body", "");
      }
    },
  }));

  useEffect(() => {
    onChange(form.values.body)
  }, [form.values.body])

  const handleUpload = (index) => {
    const path = inputRef.current.files[0].path
    onChangeValue("value", index)(path)
  }

  return (
    <div>
      {(contentType === "multipart/form-data" || contentType === "application/x-www-form-urlencoded") && (
        <>
          <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">
            {"Body - " + contentType}
          </label>
          {form.values.body.map(({ id, name, type, value }, index) => (
            <div key={index} style={{ display: "flex", alignItems: "end" }}>
              <VariableSelectWrite
                label="Name"
                handleData={onChangeValue("name", index)}
                handleSelect={onChangeValue("name", index)}
                dataState={name}
                style={{ width: "30%" }}
              />
              {contentType === "multipart/form-data" && (
                <Select
                  style={{ width: "80px", marginLeft: "12px" }}
                  label="Type"
                  value={type}
                  onChange={onChangeValue("type", index)}
                  data={[
                    { value: "file", label: "File" },
                    { value: "text", label: "Text" },
                  ]}
                />
              )}
              {form.values.body[index].type === "file" ? (
                <div
                  style={{ marginLeft: "12px", width: "35%" }}
                  className="custom-wrapper"
                >
                  <div className="block-input">
                    <VariableSelectWrite
                      placeholder="Enter full path or upload file"
                      label="Path to the file"
                      dataState={value}
                      setDataState={onChangeValue("value", index)}
                      handleData={onChangeValue("value", index)}
                      handleSelect={onChangeValue("value", index)}
                    />
                  </div>
                    <Input
                      className="custom-file-input"
                      type="file"
                      ref={inputRef}
                      onChange={() => handleUpload(index)}
                      icon={<IconFileUpload size={16} />}
                      mt="0px"
                      styles={(theme) => ({
                          input: {
                              paddingRight: "0px",
                              '&::-webkit-file-upload-button': {
                                  visibility: 'hidden',
                              },
                              cursor: 'pointer',
                          },
                      })}
                    >
                  </Input>
                </div>
              ) : (
                <VariableSelectWrite
                  style={{ marginLeft: "12px", flex: 1 }}
                  label="Value"
                  handleData={onChangeValue("value", index)}
                  handleSelect={onChangeValue("value", index)}
                  dataState={value}
                />
              )}
              <Button
                style={{ width: "80px", marginLeft: "12px" }}
                color="red"
                onClick={() => removeRow(id)}
              >
                <IconTrash size={20} />
              </Button>
            </div>
          ))}
          <div>
            <Button
              rightIcon={<IconPlus size={16} />}
              mt="sm"
              onClick={addNewRow}
            >
              Add
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default forwardRef(FormDataBody);
