import { useEffect, useState } from "react";
import { Select } from "@mantine/core";
import { getAllScript } from "../../../../request/Automation";

function RunOtherScript(props) {
    const [scripts, setScripts] = useState([])
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        script_id: '',
        description: '',
    })
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const fetchScripts = async () => {
        const scripts = await getAllScript()
        const scriptOptions = scripts.map(script => ({ ...script, value: script.key, label: script.name }))
        setScripts(scriptOptions)
    }

    const onScriptChange = (script_id) => {
        let scriptName = scripts.find(i => i.key === script_id)?.name
        if (scriptName?.length > 15) {
            scriptName = scriptName.slice(0, 15) + "..."
        }
        setDataState(s => ({ ...s, script_id, description: scriptName || "" }))
    }

    useEffect(() => {
        fetchScripts()
    }, [])

    return (
        <div>
            <Select
                searchable
                clearable
                label="Script to be execute"
                placeholder="Pick one"
                data={scripts}
                value={dataState.script_id}
                onChange={onScriptChange}
            />
        </div>
    );
}

export default RunOtherScript;
