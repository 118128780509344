import axios from "axios";
import {BASE_URL, BASE_URL_LOCAL, DEFAULT_CONFIG, LIST_PROFILE_NAME, LOCAL_LIST_PROFILE_NAME, SCRIPT, STATUS_PROFILE, TAG, UPDATE_SCRIPT, USER_SETTINGS} from "../constants/api";
let ListProfileNameController

export const createScript = ({ body, headers }) => {
    return axios({
        url: BASE_URL + SCRIPT,
        method: "post",
        data: body,
        headers: headers,
    });
}

export const updateScript = ({ body, id, headers }) => {
    return axios({
        url: BASE_URL + `${UPDATE_SCRIPT}/${id}`,
        method: "post",
        data: body,
        headers: headers,
    });
}

export const findScriptById = ({params, id, headers}) => {
    return axios({
        url: BASE_URL + `${SCRIPT}/${id}`,
        method: "get",
        params: params,
        headers: headers,
    });
}

export const getScript = async ({ page, limit }) => {
    const { data } = await axios({
        url: BASE_URL + `${SCRIPT}?limit=${limit}&page=${page}`,
        method: "get",
    });
    return data
}

export const getAllScript = async () => {
    let page = 1
    let limit = 100
    const scripts = []

    const { data, meta } = await getScript({ page, limit })
    scripts.push(...data.content)
    if (meta.current_page < meta.last_page) {
        for (let i = meta.current_page + 1; i <= meta.last_page; i++) {
            const { data } = await getScript({ page: i, limit })
            scripts.push(...data.content)
        }
    }
    return scripts
}

export const listProfileName = (limit, search, isCloud = true) => {
    let url = BASE_URL + LIST_PROFILE_NAME;
    
    if(!isCloud){
        url = BASE_URL_LOCAL + LOCAL_LIST_PROFILE_NAME
    }

    ListProfileNameController?.abort()
    ListProfileNameController = new AbortController();
    return axios({
        url: url,
        method: "get",
        params: {
            limit: limit,
            search: search
        },
        signal: ListProfileNameController.signal
    });
}

export const fetchListStatus = async () => {
    const { data } = await axios.get(BASE_URL + STATUS_PROFILE);
    return data.content;
}

export const fetchListTag = async () => {
    const { data } = await axios.get(BASE_URL + TAG);
    return data.content;
}

export const getUserSettings = async () => {
    const { data } = await axios.get(BASE_URL + USER_SETTINGS, {
        headers: {
            'Version': window?.api?.configs?.app_version,
        }
    });
    const osConfig = data.content.find((item) => item.key === "USER_AGENT_CONFIG")
    return osConfig.value.os;
}

export const fetchDefaultConfig = async () => {
    const { data } = await axios.get(BASE_URL + DEFAULT_CONFIG);
    return data.data.content;
}
