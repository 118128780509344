import { useEffect, useState } from "react";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";

const PLACEHOLDER = `[
	{
		"url": "https://www.google.com",
		"name": "_GRrfrHs",
		"value": "09ALyjir_xaSR",
		"path": "/recaptcha",
	},
]
or
abc=123;c_user=23423423t32t;
`

function SetCookies(props) {
	const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
		cookie: '',
    })
	const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
			<VariableTextarea
				dataState={dataState.cookie}
				handleData={onChangeValue('cookie')}
				handleSelect={onChangeValue('cookie')}
				placeholder={PLACEHOLDER}
			/>
		</div>
    );
}

export default SetCookies;
